import React from 'react';

import { HeaderNavToggleContent, HeaderNavToggleWrapper } from './HeaderNavToggle.styles';
import { HeaderNavToggleProps } from './HeaderNavToggle.types';

export const HeaderNavToggle = ({ onClick, opened }: HeaderNavToggleProps) => (
  <HeaderNavToggleWrapper className="header__nav-toggle" onClick={onClick}>
    <HeaderNavToggleContent
      className={`header__nav-toggle__content ${opened ? 'header__nav-toggle__content--opened' : ''}`}
    >
      &nbsp;
    </HeaderNavToggleContent>
  </HeaderNavToggleWrapper>
);
