import { RSAA, RSAAction } from 'redux-api-middleware';
import { CMS_API } from 'store/api';

import * as types from './bottomNavigationActionTypes';

export const fetchBottomNavigation = (): RSAAction<string, string, string> => ({
  [RSAA]: {
    api: CMS_API,
    endpoint: 'bottomnavigation',
    method: 'GET',
    types: [
      types.FETCH_BOTTOM_NAVIGATION_REQUEST,
      types.FETCH_BOTTOM_NAVIGATION_SUCCESS,
      types.FETCH_BOTTOM_NAVIGATION_FAILURE,
    ],
  },
});
