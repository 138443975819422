import { Button, Column } from '@melements/front-components';
import React from 'react';

import {
  HeaderNotificationContainer,
  HeaderNotificationContent,
  NotificationButtonWrapper,
} from './HeaderNotification.styles';
import { HeaderNotificationProps } from './HeaderNotification.types';

export const HeaderNotification = ({ onClick, notificationBanner }: HeaderNotificationProps) => {
  return (
    <Column size={[12]}>
      <HeaderNotificationContainer className="header__notification">
        <HeaderNotificationContent className="header__notification-content">
          {notificationBanner && notificationBanner.content}
          {notificationBanner && notificationBanner.isClosable && (
            <NotificationButtonWrapper>
              <Button
                onClick={closeNotification}
                className="notification-close"
                data-testid="notification-close"
                icon={'closeWhite'}
                type={Button.types.ICON}
                style={{ marginLeft: 'auto' }}
              />
            </NotificationButtonWrapper>
          )}
        </HeaderNotificationContent>
      </HeaderNotificationContainer>
    </Column>
  );

  function closeNotification(event?: React.MouseEvent<Element, MouseEvent>): void {
    onClick(event, notificationBanner);
  }
};
