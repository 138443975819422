import styled, { from } from 'theme';

export const CookiesInfoWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100vw - 2rem);
  background: white;
  box-shadow: ${props => props.theme.variables.shadows.shadow};
  padding: 1rem;
  max-height: 50vh;
  overflow-y: auto;
  z-index: ${props => props.theme.variables.zIndex.zIndexBackdrop};

  ${from('md')} {
    padding: 1rem 1.5rem;
    width: calc(100vw - 3rem);
  }
`;

export const CookiesInfoContent = styled.div`
  font-size: ${props => props.theme.variables.fonts.size.smaller2};
  max-width: ${props => props.theme.variables.breakpoints.xlg}px;
  margin: 0 auto;
  display: block;

  ${from('xlg')} {
    margin: 0 auto;
  }

  .button {
    display: inherit;
    margin: 1rem auto 0;
    justify-content: center;
  }

  a {
    color: ${props => props.theme.variables.colors.red};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
