import { RSAA, RSAAction } from 'redux-api-middleware';
import { CMS_API } from 'store/api';

import * as types from './topNavigationActionTypes';

export const fetchTopNavigation = (): RSAAction<string, string, string> => ({
  [RSAA]: {
    api: CMS_API,
    endpoint: 'topnavigation',
    method: 'GET',
    types: [types.FETCH_TOP_NAVIGATION_REQUEST, types.FETCH_TOP_NAVIGATION_SUCCESS, types.FETCH_TOP_NAVIGATION_FAILURE],
  },
});
