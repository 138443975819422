import styled, { from, to } from 'theme';

export const HeaderNavSublist = styled.ul<{ length: number }>`
  ${to('lg')} {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    left: 100vw;
    list-style: none;
    transition: left 0.2s ease-in-out;
    padding: 0 1rem;
    background-color: white;
    border-bottom: ${props => props.theme.variables.borders.width.base} solid
      ${props => props.theme.variables.colors.silver};
    padding-bottom: 5rem;

    &.header__nav-sub-list--opened {
      left: 0;
    }

    .header__nav-link-back {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      line-height: 1.5rem;
      padding: 1rem 2.5rem;

      &:before {
        content: '';
        background-image: url(${props => props.theme.variables.icons.svg.chevronDown});
        width: 1rem;
        height: 1rem;
        background-size: contain;
        display: inline-block;
        position: relative;
        left: -1.5rem;
        margin-left: -1rem;
        transform: rotate(90deg);
      }
    }

    li.header__nav-sub-item {
      list-style: none;
      text-transform: uppercase;
      border-top: ${props => props.theme.variables.borders.width.base} solid
        ${props => props.theme.variables.colors.silver};
      font-size: ${props => props.theme.variables.fonts.size.bigger0};

      > a {
        display: block;
        line-height: 1.5rem;
        padding: 1rem 2.5rem;
        font-family: ${props => props.theme.variables.typography.secondaryFont};
        font-weight: ${props => props.theme.variables.fonts.weight.light};
        color: ${props => props.theme.variables.colors.black};
        width: 100%;

        &.header__nav-link--active {
          font-weight: ${props => props.theme.variables.fonts.weight.semiBold};
        }
      }
    }
  }

  ${from('lg')} {
    position: absolute;
    flex-flow: column;
    background-color: white;
    margin-top: -0.5125rem;
    padding: 0.75rem 0;
    background-color: ${props => props.theme.variables.colors.white};
    border-radius: 0.1875rem;
    box-shadow: 0 0.0625rem 1rem 0 rgba(0, 0, 0, 0.22), 0 0 0.0625rem 0 rgba(0, 0, 0, 0.32);
    white-space: nowrap;
    max-height: ${props => props.length * 2.5}rem;
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    > li {
      text-transform: none;
      font-family: ${props => props.theme.variables.typography.primaryFont};
      font-size: ${props => props.theme.variables.fonts.size.smaller0};
      cursor: pointer;
      width: 100%;

      a {
        display: block;
        padding: 0 1.5rem;
        height: 2.5rem;

        &:hover,
        &.header__nav-link--active {
          background-color: ${props => props.theme.variables.colors.lightGrey};
        }
      }
    }

    .header__nav-sub-item--back {
      display: none;
    }
  }
`;
