import { Row } from 'shared';
import styled from 'theme';

export const ProductsWrapper = styled(Row)`
  margin-bottom: -1.5rem;

  .products__column {
    display: flex;
  }
`;
