import logoCorpo from 'assets/images/logo-corpo.png';
import logo from 'assets/images/logo.png';
import { ModeContext } from 'context/mode/ModeContext';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Column, Row } from 'shared';

import { NotificationBanner } from '../../store/api/cms/types/response.types';
import { resizeHeader } from '../../utils/resizeHeader/resizeHeader';

import { HeaderContent, HeaderFixed, HeaderTools, HeaderWrapper, Logo } from './Header.styles';
import { HeaderProps } from './Header.types';
import { HeaderNav } from './headerNav/HeaderNav';
import { HeaderNavToggle } from './headerNavToggle/HeaderNavToggle';
import { HeaderNotification } from './headerNotification/HeaderNotification';
import { NotificationBannerWrapper } from './headerNotification/HeaderNotification.types';
import { TopLine } from './headerTopLine/HeaderTopLine.styles';

export const Header = ({ active, links, notificationBanners, children, title, className }: HeaderProps) => {
  const MINUTE_IN_MILLISECONDS = 60 * 100 * 10;
  const context = useContext(ModeContext);
  const [isMobileOpened, setMobileOpened] = useState(false);
  const [notificationBannerWrappers, setNotificationBannerWrappers] = useState<NotificationBannerWrapper[]>([]);
  const [subMenuOpened, setSubMenuOpened] = useState<number | null>(null);

  const handleMobileOpened = () => {
    setMobileOpened(mobileOpened => !mobileOpened);
  };

  const handleCloseMobile = () => {
    setMobileOpened(false);
  };

  const handleSetSubmenuOpened = (index: number | null) => {
    setSubMenuOpened(index);
  };

  const handleCloseNotification = (
    event?: React.MouseEvent<Element, MouseEvent>,
    notificationBanner?: NotificationBanner,
  ) => {
    const notificationBannerWrappersCopy = notificationBannerWrappers.slice();
    const indexOfElementToBeClosed = notificationBannerWrappersCopy.findIndex(
      notificationBannerWrapper => notificationBannerWrapper.notificationBanner === notificationBanner,
    );
    if (indexOfElementToBeClosed !== -1) {
      notificationBannerWrappersCopy[indexOfElementToBeClosed].isClosed = true;
      notificationBannerWrappersCopy[indexOfElementToBeClosed].isVisible = false;
      saveClosedBanner(notificationBannerWrappersCopy[indexOfElementToBeClosed]);
      setNotificationBannerWrappers(notificationBannerWrappersCopy);
    }
  };

  function saveClosedBanner(element: NotificationBannerWrapper) {
    const closedNotificationBannerWrappers = loadClosedBanners();

    if (closedNotificationBannerWrappers) {
      closedNotificationBannerWrappers.push(element);
    }
    localStorage.setItem('closedNotificationBanners', JSON.stringify(closedNotificationBannerWrappers));
  }

  function loadClosedBanners(): NotificationBannerWrapper[] {
    const closedNotificationBannerWrappersString = localStorage.getItem('closedNotificationBanners');

    return closedNotificationBannerWrappersString ? JSON.parse(closedNotificationBannerWrappersString) : [];
  }

  useEffect(() => {
    const updateVisibilityOfAllNotificationBanners = () => {
      const closedNotificationBannerWrappers = loadClosedBanners();
      const visibleNotificationBannerWrappers: NotificationBannerWrapper[] = [];

      notificationBanners.forEach(notificationBanner =>
        updateVisibilityOfNotificationBanner(
          notificationBanner,
          closedNotificationBannerWrappers,
          visibleNotificationBannerWrappers,
        ),
      );

      setNotificationBannerWrappers(visibleNotificationBannerWrappers);
    };

    const updateVisibilityOfNotificationBanner = (
      notificationBanner: NotificationBanner,
      closedNotificationBannerWrappers: NotificationBannerWrapper[],
      visibleNotificationBannerWrappers: NotificationBannerWrapper[],
    ) => {
      const isAlreadyClosed = closedNotificationBannerWrappers.some(
        notificationBannerWrapper => notificationBannerWrapper.notificationBanner.id === notificationBanner.id,
      );

      if (!isAlreadyClosed) {
        const newNotificationBannerWrapper = {
          isClosed: false,
          isVisible: isNotificationBannerVisible(notificationBanner),
          notificationBanner,
        };
        visibleNotificationBannerWrappers.push(newNotificationBannerWrapper);
      }
    };

    const isNotificationBannerVisible = (notificationBanner: NotificationBanner) => {
      const currentTime = new Date();

      return currentTime > new Date(notificationBanner.startDate) && currentTime < new Date(notificationBanner.endDate);
    };

    updateVisibilityOfAllNotificationBanners();
    const interval = setInterval(updateVisibilityOfAllNotificationBanners, MINUTE_IN_MILLISECONDS);

    return () => clearInterval(interval);
  }, [notificationBanners, MINUTE_IN_MILLISECONDS]);

  useEffect(() => {
    resizeHeader();
  }, [notificationBannerWrappers]);

  return (
    <HeaderWrapper className={`header ${className || ''}`}>
      <HeaderFixed className={`header__container ${isMobileOpened ? 'header__container--opened' : ''}`}>
        <Row>
          {notificationBannerWrappers
            .filter(notificationBannerWrapper => notificationBannerWrapper.isVisible)
            .sort((left, right) =>
              left.notificationBanner.isClosable === right.notificationBanner.isClosable
                ? 0
                : left.notificationBanner.isClosable
                ? 1
                : -1,
            )
            .map((visibleNotificationBanner, index) => (
              <HeaderNotification
                onClick={handleCloseNotification}
                key={index}
                notificationBanner={visibleNotificationBanner.notificationBanner}
              />
            ))}
        </Row>
        {!notificationBannerWrappers.some(notificationBannerWrapper => notificationBannerWrapper.isVisible) && (
          <Row>
            <Column size={[12]}>
              <TopLine />
            </Column>
          </Row>
        )}
        <Row>
          <Column mx={[0, 0, 2]} my={[0, 0, 2]}>
            <HeaderContent className="header__content">
              <HeaderNavToggle opened={isMobileOpened} onClick={handleMobileOpened} />
              <Link to="/" onClick={handleCloseMobile}>
                <Logo src={context.mode === 'CORPORATION' ? logoCorpo : logo} alt="mBank" className="header__logo" />
              </Link>
              {!!title && title}
              <HeaderNav
                links={links}
                active={active}
                onClickLink={handleCloseMobile}
                onOpenSubMenu={handleSetSubmenuOpened}
                isMobileOpened={isMobileOpened}
                subMenuOpened={subMenuOpened}
              />
              <HeaderTools className="header__tools">{children && children({ handleCloseMobile })}</HeaderTools>
            </HeaderContent>
          </Column>
        </Row>
      </HeaderFixed>
    </HeaderWrapper>
  );
};
