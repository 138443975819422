import { RSAA, RSAAction } from 'redux-api-middleware';
import { PORTAL_API } from 'store/api';

import { CreateSupportIssuePayload } from './support.types';
import * as types from './supportActionTypes';

export const createSupportIssue = (body: CreateSupportIssuePayload): RSAAction<string, string, string> => ({
  [RSAA]: {
    api: PORTAL_API,
    body,
    endpoint: 'support/issues',
    method: 'POST',
    skipAuth: true,
    types: [types.CREATE_SUPPORT_ISSUE_REQUEST, types.CREATE_SUPPORT_ISSUE_SUCCESS, types.CREATE_SUPPORT_ISSUE_FAILURE],
  },
});
