import styled from 'theme';

export const DropdownToogle = styled.div`
  padding: 0.3rem 0.3rem 0.2rem 0.35rem;
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 0.9rem;
  font-weight: ${props => props.theme.variables.fonts.weight.bold};
  color: ${props => props.theme.variables.colors.black};
  cursor: pointer;
  background-color: #f5f5f7;
`;

export const ArrowDown = styled.div`
  margin-top: 0.25rem;
  margin-left: 0.15rem;
  background: url(${props => props.theme.variables.icons.svg.arrowDown});
  width: 0.45rem;
  height: 0.3rem;
  background-size: contain;
  background-repeat: no-repeat;
`;
