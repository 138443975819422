import React, { Fragment, useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { MarkdownContent, News, Products } from '../..';
import { SectionTeaser, SectionText } from '../../../app/howTo/HowTo.styles';
import { adaptApiLink, getApiResourceUrl } from '../../../utils';
import { Sidebar } from '../Sidebar/Sidebar';

import { Anchor, Content, ContentItem, GroupedContentWrapper } from './HowToGroupedContent.styles';
import { HowToGroupedContentProps } from './HowToGroupedContent.types';

export const HowToGroupedContent = ({ groups }: HowToGroupedContentProps) => {
  const [active, setActive] = useState(0);
  const [isScrollingActive, setScrollingActive] = useState(true);

  useEffect(() => {
    setScrollingActive(false);

    return () => {
      setScrollingActive(false);
    };
  }, []);

  const handleDetectActiveItem = (index: number) => (isVisible: boolean) => {
    if (isVisible && !isScrollingActive) {
      setActive(index);
    }
  };

  const handleChangeActiveItem = (index: number) => {
    setActive(index);
    setScrollingActive(true);

    window.location.hash = `content-${index}`;

    setTimeout(() => {
      setScrollingActive(false);
    }, 2000);
  };

  return (
    <GroupedContentWrapper className="grouped-content">
      <Sidebar
        groupTitles={groups.map(group => {
          return group.title;
        })}
        active={active}
        onClick={handleChangeActiveItem}
        search={false}
      />
      <Content className="grouped-content__content">
        {groups.map((group, index) => (
          <ContentItem key={index} className="grouped-content__item">
            <Anchor id={`content-${index}`} />
            <VisibilitySensor key={index} onChange={handleDetectActiveItem(index)}>
              <h3 className="grouped-content__title">{group.title}</h3>
            </VisibilitySensor>
            <Fragment>
              <SectionText>
                <MarkdownContent>{group.content}</MarkdownContent>
              </SectionText>

              {group.sections.map(section => (
                <Fragment key={section.id}>
                  {!!section.title && (
                    <SectionText>
                      <SectionTeaser>{section.title}</SectionTeaser>
                    </SectionText>
                  )}

                  {section.type === 'API' && (
                    <Products
                      products={section.blocks.map(block => ({
                        description: block.content,
                        icon: block.image ? getApiResourceUrl(block.image) : '',
                        links: block.links.map(link => adaptApiLink(link)),
                        title: block.title,
                      }))}
                      tiny={true}
                    />
                  )}

                  {section.type === 'NEWS' && (
                    <News
                      news={section.blocks.map(block => ({
                        description: block.content,
                        image: block.image ? getApiResourceUrl(block.image) : '',
                        links: block.links.map(link => adaptApiLink(link)),
                        title: block.title,
                      }))}
                    />
                  )}
                </Fragment>
              ))}
            </Fragment>
          </ContentItem>
        ))}
      </Content>
    </GroupedContentWrapper>
  );
};
