import classNames from 'classnames';
import React, { useContext } from 'react';
import { Link, LocaleContext } from 'shared';

import { HeaderNavSublist } from './HeaderSubNav.styles';
import { HeaderSubNavProps } from './HeaderSubNav.types';

export const HeaderSubNav = ({ isOpen, active, links, onClickBack, onClickLink }: HeaderSubNavProps) => {
  const localeContext = useContext(LocaleContext);

  return (
    <HeaderNavSublist
      className={classNames('header__nav-sub-list', { 'header__nav-sub-list--opened': isOpen })}
      length={links.length}
    >
      <li className="header__nav-sub-item header__nav-sub-item--back">
        <span className="header__nav-link header__nav-link-back" onClick={onClickBack}>
          {localeContext.formatMessage({ id: 'HEADER.BACK' })}
        </span>
      </li>

      {links.map((subLink, subLinkIndex) => (
        <li className="header__nav-sub-item" key={subLinkIndex}>
          <Link
            className={classNames('header__nav-link', { 'header__nav-link--active': active === subLink.url })}
            onClick={onClickLink}
            {...subLink}
          />
        </li>
      ))}
    </HeaderNavSublist>
  );
};
