import Cookies from 'js-cookie';
import React, { useContext, useEffect } from 'react';
import { Dropdown, LocaleContext } from 'shared';
import { DropdownElementProps } from 'shared/dropdown/DropdownPopup/DropdownPopup.types';

import { LocaleContainer } from './LocaleDropdown.styles';
import { LocaleDropdownProps } from './LocaleDropdown.types';

export const LocaleDropdown = ({ locales, onChangeLocale, history }: LocaleDropdownProps) => {
  const context = useContext(LocaleContext);

  useEffect(() => {
    Cookies.set('locale', context.locale, {
      SameSite: 'lax',
      expires: 14,
      secure: window.location.protocol === 'https:',
    } as any);
  }, [context.locale]);

  const handleLocaleChange = (locale: DropdownElementProps) => {
    context.updateLocale(locale.key);
    onChangeLocale(locale.key);
    history.push('/');
  };

  const currentLocales = locales.filter(locale => context.locale !== locale.key);

  return (
    <LocaleContainer>
      <Dropdown items={currentLocales} onClick={handleLocaleChange}>
        {locales.filter(locale => context.locale === locale.key)[0].label}
      </Dropdown>
    </LocaleContainer>
  );
};
