import { LocaleState } from './locale.types';
import * as types from './localeActionTypes';

export const localeReducer = (state: LocaleState, { type, meta }: { type: string; meta?: { locale: string } }) => {
  switch (type) {
    case types.SET_LOCALE: {
      return {
        ...state,
        locale: meta ? meta.locale : undefined,
      };
    }

    default: {
      return {
        locale: state ? state.locale : undefined,
      };
    }
  }
};
