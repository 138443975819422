import React from 'react';
import { Button, Link } from 'shared';

import {
  FooterBlockContent,
  FooterBlockDescription,
  FooterBlockImage,
  FooterBlockTitle,
  FooterBlockWrapper,
} from './FooterBlock.styles';
import { FooterBlockProps } from './FooterBlock.types';

export const FooterBlock = ({ title, description, image, link }: FooterBlockProps) => (
  <FooterBlockWrapper className="footer-block">
    <FooterBlockContent className="footer-block__content">
      <FooterBlockTitle className="footer-block__title">{title}</FooterBlockTitle>
      <FooterBlockDescription className="footer-block__description">{description}</FooterBlockDescription>

      {link && (
        <Button as={Link} {...link} className="footer-block__link" type={'leading'}>
          {link.label}
        </Button>
      )}
    </FooterBlockContent>

    <FooterBlockImage className="footer-block__image" style={{ backgroundImage: `url(${image})` }} />
  </FooterBlockWrapper>
);
