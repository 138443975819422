import { useCallback, useEffect, useRef, useState } from 'react';

export const useQuery = <T extends { error: boolean }>(onFetch: () => Promise<T>) => {
  const isMounted = useRef(true);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [response, setResponse] = useState<T | null>(null);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  const handleFetch = useCallback(
    (keepLastResponse?: boolean) => {
      if (!isMounted.current) {
        return;
      }

      setLoading(true);
      setError(false);

      if (!keepLastResponse) {
        setResponse(null);
      }

      onFetch()
        .then(fetchResponse => {
          if (fetchResponse.error) {
            handleError();
          } else {
            handleSuccess(fetchResponse);
          }
        })
        .catch((error: Error) => {
          handleError();
        });
    },
    [onFetch],
  );

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const handleSuccess = (fetchResponse: T) => {
    if (!isMounted.current) {
      return;
    }

    setTimeout(() => {
      setLoading(false);
      setResponse(fetchResponse);
    }, 300);
  };

  const handleError = () => {
    if (!isMounted.current) {
      return;
    }

    setError(true);
    setLoading(false);
  };

  return {
    error: isError,
    handleFetch,
    loading: isLoading,
    response,
  };
};
