import { FormApi } from 'final-form';
import { Dispatch } from 'redux-api-middleware';

import {
  DispatchAction,
  GenericFormResponse,
  SuccessCallback,
  ValidationErrorCallback,
  ValidationErrors,
} from './submitForm.types';

export const submitForm = <T extends GenericFormResponse>(dispatch: Dispatch, action: DispatchAction<T>) => async (
  formValues: object,
  formApi?: FormApi,
  onSuccess?: SuccessCallback<T>,
  onValidationError?: ValidationErrorCallback,
): Promise<ValidationErrors | T | void> => {
  const results = await dispatch<Promise<T>>(action(formValues, formApi));

  if (results.payload && results.payload.status === 400) {
    const errors = results.payload.response.errors
      ? results.payload.response.errors.reduce((prev, curr) => ({ ...prev, [curr.field]: curr.defaultMessage }), {})
      : [];

    if (onValidationError) {
      onValidationError(errors);
    }

    return Promise.resolve(errors);
  }

  if (results.error) {
    return Promise.reject(results);
  }

  if (onSuccess) {
    onSuccess(results);
  }

  return Promise.resolve();
};
