import React from 'react';
import { Link } from 'shared';

import { ProductBlockWrapper, ProductDescription, ProductIcon, ProductTitle } from './ProductBlock.styles';
import { ProductBlockProps } from './ProductBlock.types';

export const ProductBlock = ({ title, description, icon, links, tiny }: ProductBlockProps) => (
  <ProductBlockWrapper className="products__product" tiny={tiny}>
    <ProductIcon src={icon} alt={title} className="product__icon" />
    <ProductTitle className="product__title">{title}</ProductTitle>
    <ProductDescription className="product__description">{description}</ProductDescription>
    {links.map((link, index) => (
      <Link key={index} {...link} className="product__link" />
    ))}
  </ProductBlockWrapper>
);
