import React, { Fragment, lazy, Suspense, useContext, useEffect } from 'react';
import { Helmet as HeaderMeta } from 'react-helmet';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Layout, Loader, LocaleContext, ScrollToTop } from 'shared';

import { resizeHeader } from '../utils/resizeHeader/resizeHeader';

import { ContactPageContainer } from './contactPage/ContactPageContainer';
import { CookiesInfoContainer } from './core/cookiesInfo/CookiesInfoContainer';
import { FooterContainer } from './core/footer/FooterContainer';
import { HeaderContainer } from './core/header/HeaderContainer';
import { FAQContainer } from './faq/FAQContainer';
import { HomePageContainer } from './homePage/HomePageContainer';
import { HowToContainer } from './howTo/HowToContainer';
import { ReportPageContainer } from './reportPage/ReportPageContainer';
import { StaticPagesContainer } from './staticPages/StaticPagesContainer';

const Documentation = lazy(() => import('./documentation/DocumentationContainer'));

type DocumentationRouteProps = {
  match: { params: { slug: string } };
  location: RouteComponentProps['location'];
};

export const App = () => {
  const context = useContext(LocaleContext);

  /* This hook is used for pushing content down based on the height of the header */
  useEffect(() => {
    window.onresize = resizeHeader;
  }, []);

  return (
    <Router>
      <Fragment>
        <HeaderMeta>
          <title>{context.formatMessage({ id: 'GENERAL.TITLE' })}</title>
        </HeaderMeta>

        <Layout header={<HeaderContainer />} footer={<FooterContainer />}>
          <Switch>
            <Route path="/documentation/:slug">
              {({ match, location }: DocumentationRouteProps) => (
                <Suspense fallback={<Loader />}>
                  <Documentation slug={match.params.slug} location={location} />
                </Suspense>
              )}
            </Route>
            <Route path="/how-to" component={HowToContainer} />
            <Route path="/faq" component={FAQContainer} />
            <Route path="/pages/:slug" component={StaticPagesContainer} />
            <Route path="/contact" component={ContactPageContainer} />
            <Route path="/reports" component={ReportPageContainer} />
            <Route exact={true} path="/" component={HomePageContainer} />
            <Redirect to="/" />
          </Switch>
        </Layout>

        <ScrollToTop />
        <CookiesInfoContainer />
      </Fragment>
    </Router>
  );
};
