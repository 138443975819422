import chevronDown from 'assets/icons/corporation/chevron-down.svg';
import arrowDown from 'assets/icons/corporation/triangle.svg';

import { variables } from './variables';

export const corporateVariables = {
  ...variables,
  customColors: {
    ...variables.customColors,
    leadingColor: {
      base: variables.colors.blue,
      dark: '#201C17',
      mid: '#2B4F69',
    },
    overlayDefaultColor: 'rgba(1, 64, 142, 0.57)',
    stripColors: {
      stripColor1: '#2B4F69',
      stripColor2: '#9CB4BC',
      stripColor3: '#0188CA',
      stripColor4: '#6B6D6F',
      stripColor5: '#F9B001',
      stripColor6: '#201C17',
    },
  },
  icons: {
    ...variables.icons,
    svg: {
      ...variables.icons.svg,
      arrowDown,
      chevronDown,
    },
  },
};
