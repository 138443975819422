import styled, { from, to } from 'theme';

import { HeaderNavWrapper } from './headerNav/HeaderNav.styles';
import { HeaderNavToggleWrapper } from './headerNavToggle/HeaderNavToggle.styles';

export const HeaderContent = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;

  ${from('lg')} {
    align-items: center;
  }
`;

export const HeaderTools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

export const HeaderWrapper = styled.header`
  height: 3.5rem;

  ${to('lg')} {
    position: relative;
    height: 3.5rem;
  }

  ${from('lg')} {
    height: 6.5rem;
    ${HeaderNavToggleWrapper} {
      display: none;
    }
  }
`;

export const HeaderFixed = styled.div`
  z-index: ${props => props.theme.variables.zIndex.zIndexBackdrop};

  ${to('lg')} {
    position: fixed;
    width: 100vw;
    background-color: white;
    box-shadow: ${props => props.theme.variables.shadows.shadow};

    ${HeaderNavWrapper} {
      left: -100vw;
      height: 0;
      transition: left 200ms ease-in-out;
    }

    .header__nav-toggle {
      flex: 0 0 0%;
      order: 0;
    }

    a {
      order: 1;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header__nav {
      flex: 0 0 100%;
      order: 3;
    }

    .header__tools {
      right: 1rem;
      top: 0.25rem;
      height: 3rem;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      order: 2;
      flex: 0 0 80%;
    }

    .header__logo {
      position: absolute;
      left: 20vw;
      transition: left 0.2s ease-in-out;
      top: 0.6125rem;
      height: 2.25rem;
      visibility: hidden;
    }

    &.header__container--opened {
      background-color: ${props => props.theme.variables.colors.lightGrey};

      ${HeaderNavWrapper} {
        height: calc(100vh - 3.125rem);
        overflow-y: auto;
        left: 0;
      }

      .header__tools {
        display: none;
      }

      .header__logo {
        position: unset;
        left: calc(50vw - 3.125rem);
        visibility: visible;
      }
    }
  }

  ${from('lg')} {
    position: fixed;
    width: 100vw;
    max-width: ${props => props.theme.variables.breakpoints.xlg}px;
    background-color: white;
  }
`;

export const Logo = styled.img`
  height: 3.125rem;
  vertical-align: middle;
`;
