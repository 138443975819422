import React, { ChangeEvent, useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { Question } from '../..';
import { Sidebar } from '../Sidebar/Sidebar';

import { Anchor, Content, ContentItem, GroupedContentWrapper, QuestionsWrapper } from './FaqGroupedContent.styles';
import { FaqGroupedContentProps } from './FaqGroupedContent.types';

export const FaqGroupedContent = ({ groups }: FaqGroupedContentProps) => {
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(0);
  const [isScrollingActive, setScrollingActive] = useState(true);

  const filteredGroups = groups
    .map(group => {
      const filteredFaqItems = group.list.filter(faqItem => {
        return (
          faqItem.title.toLowerCase().includes(search.toLowerCase()) ||
          faqItem.content.toLowerCase().includes(search.toLowerCase())
        );
      });

      return {
        list: filteredFaqItems,
        order: group.order,
        title: group.title,
      };
    })
    .filter(group => {
      return group.list.length > 0;
    });

  useEffect(() => {
    setScrollingActive(false);

    return () => {
      setScrollingActive(false);
    };
  }, []);

  const handleDetectActiveItem = (index: number) => (isVisible: boolean) => {
    if (isVisible && !isScrollingActive) {
      setActive(index);
    }
  };

  const handleChangeActiveItem = (index: number) => {
    setActive(index);
    setScrollingActive(true);

    window.location.hash = `content-${index}`;

    setTimeout(() => {
      setScrollingActive(false);
    }, 2000);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <GroupedContentWrapper className="grouped-content">
      <Sidebar
        groupTitles={filteredGroups.map(filteredGroup => {
          return filteredGroup.title;
        })}
        active={active}
        onClick={handleChangeActiveItem}
        search={true}
        onSearch={handleSearch}
      />
      <Content className="grouped-content__content">
        {filteredGroups.map((group, index) => (
          <ContentItem key={index} className="grouped-content__item">
            <Anchor id={`content-${index}`} />
            <VisibilitySensor key={index} onChange={handleDetectActiveItem(index)}>
              <h3 className="grouped-content__title">{group.title}</h3>
            </VisibilitySensor>
            <QuestionsWrapper>
              {group.list.map((question, questionIndex) => (
                <Question key={questionIndex} title={question.title} answer={question.content} />
              ))}
            </QuestionsWrapper>
          </ContentItem>
        ))}
      </Content>
    </GroupedContentWrapper>
  );
};
