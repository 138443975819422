export { hasError } from '@melements/front-components/dist/utils/hasError/hasError';

export { adaptApiLink } from './adaptApiLink/adaptApiLink';
export { getAccountIdByUrl } from './getAccountIdByUrl/getAccountIdByUrl';
export { getApiResourceUrl } from './getApiResourceUrl/getApiResourceUrl';
export { getAppMode } from './getAppMode/getAppMode';
export { getAvailableLanguages } from './getAvailableLanguages/getAvailableLanguages';
export { getBrowserLanguage } from './getBrowserLanguage/getBrowserLanguage';
export { getEnvironmentVar } from './getEnvironmentVar/getEnvironmentVar';
export { getLocaleByDomain } from './getLocaleByDomain/getLocaleByDomain';
export { getErrorMessage } from './getErrorMessage/getErrorMessage';
