import styled from 'theme';

export const TopLine = styled.div`
  height: 0.25rem;
  display: block;
  width: 100%;

  background: ${props => `linear-gradient(
     to right,
     ${props.theme.variables.customColors.stripColors.stripColor1} 0,
     ${props.theme.variables.customColors.stripColors.stripColor1} 27%,
     ${props.theme.variables.customColors.stripColors.stripColor2} 27%,
     ${props.theme.variables.customColors.stripColors.stripColor2} 32%,
     ${props.theme.variables.customColors.stripColors.stripColor3} 32%,
     ${props.theme.variables.customColors.stripColors.stripColor3} 57%,
     ${props.theme.variables.customColors.stripColors.stripColor4} 57%,
     ${props.theme.variables.customColors.stripColors.stripColor4} 73%,
     ${props.theme.variables.customColors.stripColors.stripColor5} 73%,
     ${props.theme.variables.customColors.stripColors.stripColor5} 77%,
     ${props.theme.variables.customColors.stripColors.stripColor6} 77%,
     ${props.theme.variables.customColors.stripColors.stripColor6} 100%
   )`};
`;
