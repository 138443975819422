import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';
import { TopNavigationResponse } from 'store/api/cms/topNavigation/topNavigation.types';
import { fetchTopNavigation } from 'store/api/cms/topNavigation/topNavigationActions';

import { Header } from './Header';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchTopNavigation: () => dispatch<Promise<TopNavigationResponse>>(fetchTopNavigation()),
});

export const HeaderContainer = withRouter(
  compose(
    connect(
      undefined,
      mapDispatchToProps,
    ),
  )(Header),
);
