import { ReactNode } from 'react';

export enum AlertBoxTypes {
  SUCCESS,
  FAIL,
}

export type AlertBoxProps = {
  children: ReactNode;
  title: string;
  type: AlertBoxTypes;
};
