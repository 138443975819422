import React from 'react';
import markdown from 'showdown';

import { MarkdownContentWrapper } from './MarkdownContent.styles';
import { MarkdownContentProps } from './MarkdownContent.types';

const markDownConverter = new markdown.Converter();

export const MarkdownContent = ({ children }: MarkdownContentProps) => (
  <MarkdownContentWrapper dangerouslySetInnerHTML={{ __html: markDownConverter.makeHtml(children) }} />
);
