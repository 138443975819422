import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const ScrollToTop = ({ location }: RouteComponentProps) => {
  useEffect(() => {
    const layoutContainer = document.getElementById('layout');

    if (layoutContainer) {
      layoutContainer.scrollIntoView();
    }
  }, [location.pathname]);

  return null;
};

export default withRouter(ScrollToTop);
