import searchIcon from 'assets/icons/search.svg';
import styled, { from, to } from 'theme';

export const SidebarItem = styled.span<{ active: boolean }>`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: ${props => props.theme.variables.fonts.size.bigger0};
  line-height: 1.3;
  padding: 0.8125rem 0.5rem 0.8125rem 1.5rem;
  display: block;
  background-color: ${props => (props.active ? 'white' : 'inherit')};
  font-weight: ${props => (props.active ? props.theme.variables.fonts.weight.semiBold : 'inherit')};
  cursor: pointer;

  &:hover {
    background-color: white;
  }
`;

export const SidebarWrapper = styled.div`
  background-color: ${props => props.theme.variables.colors.lightGrey};
  top: 3rem;
  margin-bottom: 1rem;
  z-index: 10;

  ${to('md')} {
    position: sticky;
    ${SidebarItem} {
      display: none;
    }
  }

  ${from('md')} {
    margin-bottom: 0;
    padding: 1.875rem 0;
  }

  ${from('lg')} {
    top: 6.5rem;
  }
`;

export const SidebarMobileSelect = styled.select`
  background-color: transparent;
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: ${props => props.theme.variables.fonts.size.bigger0};
  line-height: 1.3;
  padding: 1rem;
  width: 100%;
  border: 0;
  border-top: 0.0625rem solid ${props => props.theme.variables.colors.platinum};
  border-bottom: 0.0625rem solid ${props => props.theme.variables.colors.platinum};
  display: block;
  font-weight: ${props => props.theme.variables.fonts.weight.semiBold};
  border-radius: 0;

  ${from('md')} {
    display: none;
  }
`;

export const SidebarItems = styled.div`
  ${from('md')} {
    position: sticky;
    top: 8rem;
  }
  ${from('lg')} {
    top: 12.5rem;
  }
`;

export const SearchInputWrapper = styled.div`
  ${from('md')} {
    width: 15.375rem;
    height: 3.25rem;
    z-index: 1;
    faqgroupedcontent.test.tsxbackground-color: #fafafa;
    margin-top: -1.875rem;
    padding-bottom: 0.975rem;
    position: sticky;
    top: 3.375rem;
    background-color: #fafafa;
  }
  ${from('lg')} {
    position: sticky;
    top: 7rem;
  }
`;

export const SearchInput = styled.input`
  ${to('md')} {
    display: none;
  }

  width: 12.5rem;
  z-index: 1;
  height: 3.25rem;
  border: none;
  border-bottom: 0.125rem solid #d4d4d4;
  background-color: ${props => props.theme.variables.colors.lightGrey};
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 0 1rem;
  margin: 0 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  text-indent: 2.5rem;
  outline: none;

  ::placeholder {
    color: ${props => props.theme.variables.customColors.inputPlaceholder};
  }
`;
