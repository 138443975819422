import React, { Fragment, useContext } from 'react';
import { Loader, LocaleContext, MarkdownContent, Message, Query } from 'shared';

import { SinglePageProps } from './SinglePage.types';

export const SinglePage = ({ onFetchPage }: SinglePageProps) => {
  const context = useContext(LocaleContext);

  return (
    <Query onFetch={onFetchPage}>
      {({ loading, error, response }) => (
        <Fragment>
          {loading && <Loader />}

          {error && (
            <Message title={context.formatMessage({ id: 'LIST.ERROR.TITLE' })}>
              {context.formatMessage({ id: 'LIST.ERROR.CONTENT' })}
            </Message>
          )}

          {!loading && response && !response.payload.title && (
            <Message title={context.formatMessage({ id: 'LIST.EMPTY.TITLE' })}>
              {context.formatMessage({ id: 'LIST.EMPTY.CONTENT' })}
            </Message>
          )}

          {!loading && response && (
            <Fragment>
              <h1>{response.payload.title}</h1>
              <MarkdownContent>{response.payload.content}</MarkdownContent>
            </Fragment>
          )}
        </Fragment>
      )}
    </Query>
  );
};
