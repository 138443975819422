import loaderCorpo from 'assets/images/loader-corpo.gif';
import loaderInd from 'assets/images/loader-ind.gif';
import styled from 'theme';

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderImage = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  margin: 4rem;
  background: url(${props => (props.theme.mode === 'CORPORATION' ? loaderCorpo : loaderInd)}) no-repeat center center;
  background-size: 3.5rem;
`;
