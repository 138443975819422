import fbIcon from 'assets/images/socials.png';
import styled, { from, to } from 'theme';

export const FooterBottomWrapper = styled.div`
  background-color: white;
  padding: 1rem 2rem;
  margin: 0 -2rem;
  font-size: 0.75rem;
  color: ${props => props.theme.variables.customColors.lightGreyText};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${from('md')} {
    padding: 1rem 8rem;
    margin: 0rem -8rem;
  }

  ${to('md')} {
    flex-flow: column;
    align-items: flex-start;
  }
`;

export const FooterBottomLinks = styled.div`
  display: flex;

  ${to('md')} {
    order: -2;
    margin: 0.5rem 0 0;
    flex-wrap: wrap;
    flex-flow: column;
  }

  .footer__bottom__link {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover {
      color: ${props => props.theme.variables.customColors.leadingColor.mid};
    }

    ${to('md')} {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const FooterBottomSocial = styled.div`
  display: flex;

  a {
    width: 1.75rem;
    height: 1.75rem;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  ${to('md')} {
    order: -1;
    margin: 2rem 0 1rem;
  }
`;

export const FB = styled.a`
  background-image: url(${fbIcon});
  background-size: cover;
`;
