import styled, { from } from 'theme';

import { SidebarWrapper } from '../Sidebar/Sidebar.styles';

export const GroupedContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column;

  ${from('md')} {
    flex-flow: row;
  }

  ${SidebarWrapper} {
    ${from('md')} {
      width: 15.375rem;
      margin-right: 4rem;
    }
  }
`;

export const Content = styled.div`
  padding: 0 1rem;
  flex: 1 1 auto;

  ${from('md')} {
    padding: 2.75rem 1.5rem 1.875rem 0;
    width: 29.125rem;
  }

  ${from('lg')} {
    max-width: 43.125rem;
  }

  ${from('xlg')} {
    margin-right: 7.5rem;
    padding-right: 0;
    max-width: 57.875rem;
  }
`;

export const ContentItem = styled.section`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  padding-bottom: 3.2rem;
  border-bottom: 0.0625rem solid ${props => props.theme.variables.customColors.boxBorder};
  margin-bottom: 1.5rem;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .grouped-content__title {
    font-size: ${props => props.theme.variables.fonts.size.bigger2};
    margin-bottom: 1rem;
  }
`;

export const Anchor = styled.span`
  top: -8rem;
  position: relative;
  display: inline-block;

  ${from('md')} {
    top: -7rem;
  }
`;

export const QuestionsWrapper = styled.div`
  .question {
    margin-bottom: 1.25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
