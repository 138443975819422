import React, { useRef } from 'react';
import { Link } from 'shared';
import { useHeight } from 'shared/hooks';

import { LinksGroupWrapper } from './LinksGroup.styles';
import { LinksGroupProps } from './LinksGroup.types';

export const LinksGroup = ({ links }: LinksGroupProps) => {
  const ref = useRef(null);
  const height = useHeight(ref);

  return (
    <LinksGroupWrapper ref={ref} height={height} className="footer__group__links">
      {links.map((link, index) => (
        <Link className="footer__group__link" key={index} {...link} />
      ))}
    </LinksGroupWrapper>
  );
};
