import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';
import { HowToResponse } from 'store/api/cms/howTo/howTo.types';
import { fetchHowTo } from 'store/api/cms/howTo/howToActions';

import { HowTo } from './HowTo';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchHowTo: () => dispatch<Promise<HowToResponse>>(fetchHowTo()),
});

export const HowToContainer = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(HowTo);
