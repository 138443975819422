import React, { useEffect, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { RefreshingReCaptchaProps } from './RefreshingReCaptcha.types';

export const RefreshingReCaptcha = ({ onVerify, refreshTime }: RefreshingReCaptchaProps) => {
  const [trigger, setTrigger] = useState(false);
  const [reCaptchaKey, setReCaptchaKey] = useState(`ReCaptchaKey-${trigger}`);

  useEffect(() => {
    const tick = () => {
      setTrigger(!trigger);
      setReCaptchaKey(`${reCaptchaKey}${trigger}`);
    };
    if (refreshTime != null) {
      const interval = setInterval(tick, refreshTime);

      return () => clearInterval(interval);
    }

    return;
  }, [refreshTime, reCaptchaKey, trigger]);

  return <GoogleReCaptcha key={reCaptchaKey} onVerify={onVerify} />;
};
