import styled, { from } from 'theme';

export const ContactPageWrapper = styled.div`
  .question {
    margin-bottom: 2rem;
  }

  .form__content {
    margin: 0;

    ${from('lg')} {
      margin: 0 1.5rem 0 0;
    }
  }

  p {
    font-weight: 300;
    line-height: 1.8;
  }

  ${from('lg')} {
    .contact-intro {
      margin-top: 2rem;
      display: flex;
      flex-flow: column;
      padding-bottom: 4rem;
    }
  }

  .contact-form__submit {
    margin-top: 1rem !important;
  }
`;

export const ChevronLink = styled.div`
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  &:before {
    content: '';
    background-image: url(${props => props.theme.variables.icons.svg.chevronDown});
    width: 1rem;
    height: 1rem;
    background-size: contain;
    display: inline-block;
    position: relative;
    transform: rotate(-90deg);
  }
`;

export const ContactInfo = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: ${props => props.theme.variables.customColors.textColor};
`;

export const BoldedFragment = styled.div`
  margin-bottom: 1rem;
  display: flex;
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-weight: ${props => props.theme.variables.fonts.weight.bold};
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: ${props => props.theme.variables.customColors.textColor};
`;

export const FooterDescription = styled.p`
  color: ${props => props.theme.variables.customColors.textColor};
  font-size: 0.8rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;
