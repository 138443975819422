import React, { useState } from 'react';

import { FooterGroupExpandButton, FooterGroupTitle, FooterGroupWrapper } from './FooterGroup.styles';
import { FooterGroupProps } from './FooterGroup.types';
import { LinksGroup } from './linksGroup/LinksGroup';

export const FooterGroup = ({ links, title }: FooterGroupProps) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const handleTitleClick = () => {
    setCollapsed(collapsed => !collapsed);
  };

  return (
    <FooterGroupWrapper className={`footer__group ${isCollapsed ? 'footer__group--collapsed' : ''}`}>
      <FooterGroupTitle className="footer__group__title" onClick={handleTitleClick}>
        {title}
        <FooterGroupExpandButton />
      </FooterGroupTitle>

      <LinksGroup links={links} />
    </FooterGroupWrapper>
  );
};
