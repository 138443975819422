import { RSAA, RSAAction } from 'redux-api-middleware';
import { CMS_API } from 'store/api';

import * as types from './reportPageActionTypes';

export const fetchReportPage = (): RSAAction<string, string, string> => ({
  [RSAA]: {
    api: CMS_API,
    endpoint: 'reportpage',
    method: 'GET',
    types: [types.FETCH_REPORT_PAGE_REQUEST, types.FETCH_REPORT_PAGE_SUCCESS, types.FETCH_REPORT_PAGE_FAILURE],
  },
});
