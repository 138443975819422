import { RSAA, RSAAction } from 'redux-api-middleware';
import { CMS_API } from 'store/api';

import * as types from './howToActionTypes';

export const fetchHowTo = (): RSAAction<string, string, string> => ({
  [RSAA]: {
    api: CMS_API,
    endpoint: 'howto',
    method: 'GET',
    types: [types.FETCH_HOW_TO_REQUEST, types.FETCH_HOW_TO_SUCCESS, types.FETCH_HOW_TO_FAILURE],
  },
});
