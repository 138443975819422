import styled, { from } from 'theme';

export const NewsBlockWrapper = styled.section`
  margin-bottom: 3rem;
  display: flex;
  flex-flow: column;
  width: 100%;

  ${from('md')} {
    margin-bottom: 1.5rem;
  }

  .news__link {
    font-family: ${props => props.theme.variables.typography.secondaryFont};
    font-weight: ${props => props.theme.variables.fonts.weight.bold};
    font-size: ${props => props.theme.variables.fonts.size.base};
    color: ${props => props.theme.variables.customColors.leadingColor.base};
    text-transform: uppercase;
    text-align: center;
    margin-right: 1rem;

    &:hover {
      color: ${props => props.theme.variables.customColors.leadingColor.mid};
    }
  }
`;

export const LinksWrapper = styled.div``;

export const NewsImage = styled.div`
  margin: 0 auto 2rem;
  width: 100%;
  background-size: cover;
  background-position: center center;
  flex: 0 0 9rem;
`;

export const NewsTitle = styled.h3`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const NewsDescription = styled.span`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  margin-bottom: 1rem;
  line-height: 1.25;
  flex: 1 1 auto;
`;
