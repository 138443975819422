import React from 'react';
import { Button, LocaleContext } from 'shared';

import { CookiesInfoContent, CookiesInfoWrapper } from './CookiesInfo.styles';
import { CookiesInfoProps } from './CookiesInfo.types';

export const CookiesInfo = ({ onApprove, isApproved }: CookiesInfoProps) =>
  !isApproved ? (
    <LocaleContext.Consumer>
      {({ formatMessage }) => (
        <CookiesInfoWrapper className="cookies-info">
          <CookiesInfoContent className="cookies-info__content">
            <div dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'GENERAL.COOKIES-INFO' }) }} />
            <Button className="cookies-info__button" onClick={onApprove} type={'leading'}>
              {formatMessage({ id: 'GENERAL.OK' })}
            </Button>
          </CookiesInfoContent>
        </CookiesInfoWrapper>
      )}
    </LocaleContext.Consumer>
  ) : null;
