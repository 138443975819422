import { createGlobalStyle } from 'theme';

export const GlobalStyle = createGlobalStyle`
  input {
    &::-ms-clear { 
      display: none; 
    } 
  }

  body {
    scroll-behavior: smooth;
  }

  html, body, #developer-portal {
    height: 100%;
  }

  html, body {
    -webkit-overflow-scrolling: touch;
    word-break: break-word;
  }

  #developer-portal {
    display: flex;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-flow: column;
    max-width: ${props => props.theme.variables.breakpoints.xlg}px;
    
    .notifications__box {
      z-index: 101;
    }
  }

  .grecaptcha-badge { 
    visibility: hidden; 
  }
`;
