import { Row } from 'shared';
import styled, { from } from 'theme';

export const NewsWrapper = styled(Row)`
  margin-bottom: -3rem;

  ${from('md')} {
    margin-bottom: -1.5rem;
  }

  .news__column {
    display: flex;
  }
`;
