import { css } from '@melements/front-components';

export const buttonRuleSets = {
  gray: {
    common: css`
      font-weight: ${p => p.theme.variables.fonts.weight.medium};
      text-transform: uppercase;
    `,
    disabled: css`
      color: ${p => p.theme.variables.customColors.leadingColor.mid};
      background-color: ${p => p.theme.variables.colors.silver};
    `,
    enabled: css`
      color: #ffffff;
      background-color: ${p => p.theme.variables.colors.midGrey};

      &:hover:not(:focus) {
        background-color: ${p => p.theme.variables.colors.darkGrey};
      }

      &:focus {
        background-color: ${p => p.theme.variables.colors.darkGrey};
      }
    `,
  },
  leading: {
    common: css`
      font-weight: ${p => p.theme.variables.fonts.weight.medium};
      text-transform: uppercase;
    `,
    disabled: css`
      color: ${p => p.theme.variables.customColors.leadingColor.mid};
      background-color: ${p => p.theme.variables.colors.silver};
    `,
    enabled: css`
      color: #ffffff;
      background-color: ${p => p.theme.variables.customColors.leadingColor.base};

      &:hover:not(:focus) {
        background-color: ${p => p.theme.variables.customColors.leadingColor.mid};
      }

      &:focus {
        background-color: ${p => p.theme.variables.customColors.leadingColor.dark};
      }
    `,
  },
};
