import styled, { from, to } from 'theme';

import { LinksGroupWrapper } from './linksGroup/LinksGroup.styles';

export const FooterGroupExpandButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-size: cover;
  background-image: url(${props => props.theme.variables.icons.svg.chevronDown});
  display: none;
  transform: rotate(-180deg);
`;

export const FooterGroupWrapper = styled.section`
  display: flex;
  flex-flow: column;
  flex: 0 0 25%;

  ${LinksGroupWrapper} {
    transition: height 0.2s;
    overflow: hidden;
    margin-bottom: 0.875rem;
  }

  ${to('md')} {
    flex: 1 1 auto;

    &:not(:last-child) {
      border-bottom: ${props => props.theme.variables.borders.width.base} solid
        ${props => props.theme.variables.customColors.superLightGrey};
      margin-bottom: 0.875rem;
    }

    ${FooterGroupExpandButton} {
      transition: all 0.4s;
      display: block;
    }

    &.footer__group--collapsed {
      ${LinksGroupWrapper} {
        height: 0;
      }

      ${FooterGroupExpandButton} {
        transform: rotate(0deg);
      }
    }
  }
`;

export const FooterGroupTitle = styled.h3`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-weight: ${props => props.theme.variables.fonts.weight.extraBold};
  font-size: ${props => props.theme.variables.fonts.size.bigger0};
  color: ${props => props.theme.variables.colors.darkGrey};
  text-transform: uppercase;
  padding: 0 0 0.2rem;
  line-height: 1.8rem;

  ${to('md')} {
    font-size: ${props => props.theme.variables.fonts.size.base};
    display: flex;
    justify-content: space-between;
  }

  ${from('md')} {
    &:after {
      content: '';
      width: 30%;
      height: 0.125rem;
      background-color: ${props => props.theme.variables.customColors.leadingColor.base};
      display: block;
      margin: 0.875rem 0 0.3rem;
    }
  }
`;
