import { createAuthMiddleware, createAuthReducer } from '@tshio/redux-api-auth-middleware';
import contentMiddleware from '@tshio/redux-api-content-middleware';
import createEndpointMiddleware from '@tshio/redux-api-endpoint-middleware';
import paramsMiddleware from '@tshio/redux-api-params-middleware';
import { applyMiddleware, combineReducers, compose, createStore as reduxCreateStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';
import { ModeType } from 'theme/Theme.types';
import { getEnvironmentVar } from 'utils';

import { CMS_API, PORTAL_API } from './api';
import { createModeMiddleware } from './api/cms/middlewares/modeMiddleware';
import { localeReducer } from './state/locale/localeReducer';

const endpointMiddleware = createEndpointMiddleware({
  apis: {
    default: {
      apiUrl: getEnvironmentVar('REACT_APP_PORTAL_DEV_API_URL'),
    },
    [CMS_API]: {
      apiUrl: getEnvironmentVar('REACT_APP_CMS_API_URL'),
      skipAuth: true,
    },
    [PORTAL_API]: {
      apiUrl: getEnvironmentVar('REACT_APP_PORTAL_DEV_API_URL'),
    },
  },
});

const authMiddleware = createAuthMiddleware({
  authConfig: {
    header: 'Authorization',
    type: 'Bearer',
  },
});

const middlewares = [endpointMiddleware, contentMiddleware, paramsMiddleware, authMiddleware, apiMiddleware];

const authReducer = createAuthReducer();

export const createStore = ({ locale, appMode }: { locale: string; appMode: ModeType }) =>
  reduxCreateStore(
    combineReducers({
      auth: authReducer,
      locale: localeReducer,
    }),
    {
      locale: {
        locale,
      },
    },
    compose(
      applyMiddleware(createModeMiddleware(appMode), ...middlewares),
      devToolsEnhancer({ name: 'Primary' }),
    ),
  );
