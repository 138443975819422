import { ModeContext } from 'context/mode/ModeContext';
import React, { Fragment, useContext } from 'react';
import { AutoDetectLocaleChange, Button, Header as HeaderBase, LocaleContext, Query } from 'shared';
import { adaptApiLink, getAvailableLanguages, getEnvironmentVar } from 'utils';

import { LocaleDropdownContainer as LocaleDropdown } from '../localeDropdown/LocaleDropdownContainer';

import { HeaderProps } from './Header.types';

export const Header = ({ location, onFetchTopNavigation }: HeaderProps) => {
  const context = useContext(LocaleContext);
  const modeContext = useContext(ModeContext);

  const handleRedirect = (link: string) => () => {
    window.location.href = link;
  };

  return (
    <Query onFetch={onFetchTopNavigation}>
      {({ error, loading, response, handleFetch }) => (
        <Fragment>
          <AutoDetectLocaleChange onChange={handleFetch} />
          <HeaderBase
            active={location.pathname}
            links={
              !loading && !error && response && response.payload && response.payload.links
                ? response.payload.links.map(l => adaptApiLink(l))
                : []
            }
            notificationBanners={
              !loading && !error && response && response.payload ? response.payload.notificationBanners : []
            }
          >
            {() => (
              <Fragment>
                <Button
                  onClick={handleRedirect(getEnvironmentVar('REACT_APP_PORTAL_URL') as string)}
                  type="leading"
                  className="button--authorization"
                >
                  {context.formatMessage({ id: 'HEADER.PORTAL-API' })}
                </Button>
                <LocaleDropdown locales={getAvailableLanguages(modeContext.mode)} />
              </Fragment>
            )}
          </HeaderBase>
        </Fragment>
      )}
    </Query>
  );
};
