import styled, { from, to } from 'theme';

export const HeaderNavWrapper = styled.nav`
  background-color: white;
  left: 0;
  position: relative;
  z-index: 100;

  ul.header__nav-list,
  li.header__nav-item {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.header__nav-list {
    padding: 0 1rem;

    background-color: white;
    border-bottom: ${props => props.theme.variables.borders.width.base} solid
      ${props => props.theme.variables.colors.silver};

    ${to('lg')} {
      padding-bottom: 5rem;
    }
  }

  li.header__nav-item {
    text-transform: uppercase;
    border-top: ${props => props.theme.variables.borders.width.base} solid
      ${props => props.theme.variables.colors.silver};
    font-size: ${props => props.theme.variables.fonts.size.bigger0};

    > a {
      font-family: ${props => props.theme.variables.typography.secondaryFont};
      font-weight: ${props => props.theme.variables.fonts.weight.light};
      color: ${props => props.theme.variables.colors.black};
      display: block;
      padding: 0 2.5rem 0;
      line-height: 3.75rem;

      &.header__nav-link--active {
        font-weight: ${props => props.theme.variables.fonts.weight.semiBold};
      }

      ${to('lg')} {
        &.header__nav-link--parent {
          &::after {
            content: '';
            background-image: url(${props => props.theme.variables.icons.svg.chevronDown});
            width: 1rem;
            height: 1rem;
            background-size: contain;
            display: inline-block;
            transform: rotate(-90deg);
            position: absolute;
            right: 1.5rem;
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  ${to('lg')} {
    overflow-x: hidden;
  }

  ${from('lg')} {
    ul.header__nav-list {
      display: flex;
      border: 0;
      padding: 0 0.7rem;
    }

    li.header__nav-item {
      border: 0;
      line-height: 2.75rem;
      position: relative;
      padding: 0 1.5rem 0;

      > a {
        padding: 0;
        line-height: auto;
        font-family: ${props => props.theme.variables.typography.secondaryFont};
        font-size: ${props => props.theme.variables.fonts.size.root};

        &::after {
          display: block;
          bottom: 0.625rem;
          position: absolute;
          content: '';
          height: 0.125rem;
          width: 0%;
          background-color: ${props => props.theme.variables.colors.red};
          transition: width 200ms ease-in-out;
        }

        &.header__nav-link--active {
          &::after {
            width: 2.4rem;
          }
        }

        &.header__nav-link--parent {
          &::before {
            content: '';
            background-image: url(${props => props.theme.variables.icons.svg.chevronDown});
            width: 0.75rem;
            height: 0.75rem;
            background-size: contain;
            display: block;
            position: absolute;
            right: 0.25rem;
            top: 1.45rem;
            transition: transform 0.2s ease-in-out;
          }
        }

        &.header__nav-link--active {
          font-weight: ${props => props.theme.variables.fonts.weight.semiBold};
        }
      }

      &:hover {
        > a {
          &::after {
            width: 2.4rem;
          }
        }
        > .header__nav-link--parent {
          &::before {
            transform: rotate(180deg);
          }
        }
      }

      &:not(:hover) {
        ul.header__nav-sub-list {
          visibility: hidden;
          max-height: 0;
        }
      }
    }
  }
`;
