import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Column, Row } from 'shared';

import { SinglePage } from './singlePage/SinglePage';
import { BoxWrapper, PageBackgroundBox } from './StaticPages.styles';
import { StaticPagesProps } from './StaticPages.types';

export const StaticPages = ({ onFetchStaticPage, onFetchStaticPageBySlug }: StaticPagesProps) => {
  const onFetchPage = (type: string) => () => onFetchStaticPage(type);
  const onFetchPageBySlug = (slug: string) => () => onFetchStaticPageBySlug(slug);

  return (
    <Row height="100%">
      <Column>
        <PageBackgroundBox px={[1, 2, 2, 6]} className="page-wrapper">
          <Row>
            <Column size={[0, 0, 2]} />
            <Column size={[12, 12, 7]} py="2rem">
              <BoxWrapper className="page-wrapper__content">
                <Switch>
                  <Route path={'/pages/privacy-policy'}>
                    <SinglePage key="PRIVACY" onFetchPage={onFetchPage('PRIVACY')} />
                  </Route>
                  <Route path={'/pages/cookies-policy'}>
                    <SinglePage key="COOKIES" onFetchPage={onFetchPage('COOKIES')} />
                  </Route>
                  <Route path={'/pages/service-policy'}>
                    <SinglePage key="STATUTE" onFetchPage={onFetchPage('STATUTE')} />
                  </Route>
                  <Route path={'/pages/:slug'}>
                    {({
                      match: {
                        params: { slug },
                      },
                    }: {
                      match: { params: { slug: string } };
                    }) => <SinglePage key={slug} onFetchPage={onFetchPageBySlug(slug)} />}
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </BoxWrapper>
            </Column>
            <Column size={[0, 0, 3]} />
          </Row>
        </PageBackgroundBox>
      </Column>
    </Row>
  );
};
