import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';
import { StaticPageResponse } from 'store/api/cms/staticPage/staticPage.types';
import { fetchStaticPage, fetchStaticPageBySlug } from 'store/api/cms/staticPage/staticPageActions';

import { StaticPages } from './StaticPages';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchStaticPage: (type: string) => dispatch<Promise<StaticPageResponse>>(fetchStaticPage(type)),
  onFetchStaticPageBySlug: (slug: string) => dispatch<Promise<StaticPageResponse>>(fetchStaticPageBySlug(slug)),
});

export const StaticPagesContainer = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(StaticPages);
