import styled, { from, to } from 'theme';

export const HeaderBlockWrapper = styled.section`
  background-size: cover;
  background-position: center center;
`;

export const HeaderBlockWrapperLayer = styled.div<{ overlayColor?: string }>`
  background-color: ${props =>
    props.overlayColor ? props.overlayColor : props.theme.variables.customColors.overlayDefaultColor};
  padding: 1rem 2rem;
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  color: white;
  display: block;

  .header-block__link {
    margin: 2rem auto 0;
    align-items: center;
    height: 2rem;
  }

  ${from('md')} {
    padding: 2rem 4rem;
    text-align: center;

    .header-block__link {
      display: inline-flex;
    }
  }

  ${to('md')} {
    .header-block__link {
      justify-content: center;
      display: flex;
    }
  }

  ${from('lg')} {
    padding: 2rem 12rem;
    min-height: 18rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 0.0625rem;

    .header-block__link {
      margin-bottom: -1rem;
      align-self: flex-start;
    }
  }
`;

export const HeaderBlockTitle = styled.h3`
  font-weight: ${props => props.theme.variables.fonts.weight.medium};
  font-size: 2rem;
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
  margin-bottom: 0.5rem;
  display: block;

  ${from('lg')} {
    font-size: 3rem;
  }
`;

export const HeaderBlockDescription = styled.span`
  display: block;
  font-weight: ${props => props.theme.variables.fonts.weight.extraLight};
  color: ${props => props.theme.variables.customColors.bannerDescriptionText};
  line-height: 1.2;
  font-size: 1.25rem;
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5);

  ${from('md')} {
    font-size: 1.5rem;
  }
`;
