import React from 'react';

import { SectionContent, SectionTitle, SectionWrapper } from './Section.styles';
import { SectionProps } from './Section.types';

export const Section = ({ title, children }: SectionProps) => (
  <SectionWrapper className="section">
    {title && <SectionTitle className="section__title">{title}</SectionTitle>}
    <SectionContent className="section__content">{children}</SectionContent>
  </SectionWrapper>
);
