import { useContext, useEffect, useState } from 'react';
import { LocaleContext } from 'shared';

export const AutoDetectLocaleChange = ({ onChange }: { onChange: (locale: string) => void }) => {
  const context = useContext(LocaleContext);
  const [currentLocale, setCurrentLocale] = useState(context.locale);

  useEffect(() => {
    if (context.locale !== currentLocale) {
      setCurrentLocale(context.locale);
      onChange(context.locale);
    }
  }, [context, onChange, currentLocale]);

  return null;
};
