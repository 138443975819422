import { from, to } from '@melements/front-components';
import * as styledComponents from 'styled-components';

import { Theme } from './Theme';
import { Theme as ThemeInterface } from './Theme.types';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export { css, createGlobalStyle, keyframes, Theme, ThemeProvider, from, to };

export default styled;
