import { Button } from '@melements/front-components';
import React, { useContext } from 'react';
import { Column, FooterBlock, HeaderBlock, Link, Loader, LocaleContext, Message, Query, Row, Section } from 'shared';

import { adaptApiLink, getApiResourceUrl } from '../../utils';

import { Report, ReportIcon, ReportList, ReportName, ReportPageSection, ReportPageText } from './ReportPage.styles';
import { ReportPageProps } from './ReportPage.types';

export const ReportPage = ({ onFetchReportPage }: ReportPageProps) => {
  const localeContext = useContext(LocaleContext);

  const removeExtension = (fileName: string): string => {
    return fileName.substr(0, fileName.lastIndexOf('.')) || fileName;
  };

  return (
    <LocaleContext.Consumer>
      {({ formatMessage }) => (
        <Query onFetch={onFetchReportPage}>
          {({ error, loading, response }) => (
            <Row height="100%" style={{ flexFlow: 'column' }}>
              {response &&
                response.payload.banners &&
                response.payload.banners.map(
                  banner =>
                    banner.type === 'HERO' && (
                      <Column mx={[1, 2]} mt={[1, 1, 0]} mb={[1, 0]} key={banner.id} flexBasis="auto">
                        <HeaderBlock
                          description={banner.content}
                          image={banner.image ? getApiResourceUrl(banner.image) : ''}
                          link={banner.link ? adaptApiLink(banner.link) : undefined}
                          title={banner.title}
                          overlayColor={banner.overlayColor}
                        />
                      </Column>
                    ),
                )}

              <Column flexBasis="auto">
                {loading && <Loader />}

                {error && (
                  <Message title={formatMessage({ id: 'LIST.ERROR.TITLE' })}>
                    {formatMessage({ id: 'LIST.ERROR.CONTENT' })}
                  </Message>
                )}

                {!loading && response && response.payload.text && (
                  <ReportPageSection className="report__page__section">
                    <ReportPageText>
                      {response.payload.text.split('\\n').map((item, i) => (
                        <p key={i}>{item}</p>
                      ))}
                    </ReportPageText>
                    <ReportList>
                      {response.payload.reports.map(
                        (report, index) =>
                          report.resource && (
                            <Report className="report" key={index}>
                              <ReportIcon className="report__icon" />
                              <ReportName className="report__name">{removeExtension(report.resource.name)}</ReportName>
                              {report.resource.url && (
                                <Button
                                  as={Link}
                                  {...{
                                    external: true,
                                    target: '_blank',
                                    url: `${getApiResourceUrl(report.resource)}`,
                                  }}
                                  type="leading"
                                  className="button__download"
                                >
                                  {localeContext.formatMessage({ id: 'REPORT.DOWNLOAD' })}
                                </Button>
                              )}
                            </Report>
                          ),
                      )}
                    </ReportList>
                  </ReportPageSection>
                )}
              </Column>
              {response &&
                response.payload.banners &&
                response.payload.banners.map(
                  banner =>
                    banner.type === 'FOOTER' && (
                      <Column mx={[1, 2, 2, 6]} mt={[1, '2rem']} mb={[0, '2rem']} key={banner.id}>
                        <Section>
                          <FooterBlock
                            description={banner.content}
                            image={banner.image ? getApiResourceUrl(banner.image) : ''}
                            link={banner.link ? adaptApiLink(banner.link) : undefined}
                            title={banner.title}
                          />
                        </Section>
                      </Column>
                    ),
                )}
            </Row>
          )}
        </Query>
      )}
    </LocaleContext.Consumer>
  );
};
