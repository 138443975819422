import React from 'react';

import { FormContent, FormSubTitle, FormTitle, FormWrapper } from './Form.styles';
import { FormProps } from './Form.types';

export const Form = ({ title, subTitle, children, className }: FormProps) => (
  <FormWrapper className={`${className} form`}>
    {title && <FormTitle className="form__title">{title}</FormTitle>}
    <FormSubTitle className="form__sub-title">{subTitle}</FormSubTitle>
    <FormContent className="form__content">{children}</FormContent>
  </FormWrapper>
);
