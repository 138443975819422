import React, { useRef, useState } from 'react';

import { CollapseContent, CollapseTitle, CollapseWrapper } from './Collapse.styles';
import { CollapseProps } from './Collapse.types';

export const Collapse = ({ collapsed, title, children, className }: CollapseProps) => {
  const ref = useRef(null);
  const [isCollapsed, setCollapsed] = useState(collapsed !== undefined ? collapsed : true);

  const handleTitleClick = () => {
    setCollapsed(current => !current);
  };

  return (
    <CollapseWrapper className={`collapse ${isCollapsed ? 'collapse--collapsed' : ''} ${className || ''}`}>
      <CollapseTitle className="collapse_title" onClick={handleTitleClick}>
        {title(isCollapsed)}
      </CollapseTitle>

      <CollapseContent className="collapse_content" ref={ref} height={isCollapsed ? 0 : 1}>
        {children}
      </CollapseContent>
    </CollapseWrapper>
  );
};
