import { Theme as BaseTheme } from '@melements/front-components';

import { buttonRuleSets } from './ruleSets/button';
import { ModeType, Theme as ThemeInterface } from './Theme.types';
import { corporateVariables } from './variables/corporateVariables';
import { variables } from './variables/variables';

export const Theme: ThemeInterface = {
  ...BaseTheme,
  mode: 'INDIVIDUAL',
  ruleSets: {
    button: buttonRuleSets,
  },
  variables,
};

export const CorporateTheme: ThemeInterface = {
  ...Theme,
  mode: 'CORPORATION',
  variables: corporateVariables,
};

export const getThemeByMode = (mode: ModeType): ThemeInterface =>
  ({
    CORPORATION: CorporateTheme,
    INDIVIDUAL: Theme,
  }[mode]);
