import { Theme } from '@melements/front-components';
import chevronDown from 'assets/icons/chevron-down.svg';
import closeWhite from 'assets/icons/close-white.svg';
import closeIcon from 'assets/icons/close.svg';
import failIcon from 'assets/icons/icon-error.svg';
import successIcon from 'assets/icons/icon-success.svg';
import menuEnvelope from 'assets/icons/menu-envelope.svg';
import menuQeadquater from 'assets/icons/menu-qeadquater.svg';
import reportIcon from 'assets/icons/report.svg';
import arrowDown from 'assets/icons/triangle.svg';

const icons = {
  ...Theme.variables.icons,
  svg: {
    ...Theme.variables.icons.svg,
    arrowDown,
    chevronDown,
    closeIcon,
    closeWhite,
    failIcon,
    menuEnvelope,
    menuQeadquater,
    reportIcon,
    successIcon,
  },
};

const layout = {
  column: {
    count: 12,
    gap: '1.5rem',
    width: '5.4375rem',
  },
};

const customColors = {
  ...Theme.variables.customColors,
  bannerDescriptionText: '#f6f6f6',
  boxBorder: '#d4d4d4',
  inputPlaceholder: '#626262',
  lightGrey: '#fafafa',
  lightGreyText: '#9B9B9B',
  linkColor: '#e41e0a',
  modal: {
    backgroundColor: '#fafafa',
  },
  overlayDefaultColor: 'rgba(0, 0, 0, 0.35)',
  redoc: {
    endpointUrl: '#b1b1b1',
    fieldTextColor: '#4a4a4a',
    jsonKey: '#B9E1E5',
    operationBackground: '#36363b',
    operationElementsBackground: '#232326',
    requestError: '#E65353',
    requestOk: '#99C6CB',
  },
  secondaryTextColor: '#232326',
  stripColors: {
    stripColor1: '#e41e0a',
    stripColor2: '#201c17',
    stripColor3: '#f39100',
    stripColor4: '#cc0b18',
    stripColor5: '#0077bd',
    stripColor6: '#18a035',
  },
  superLightGrey: '#ececec',
  textColor: '#36363b',
};

export const variables = {
  ...Theme.variables,
  customColors,
  icons,
  layout,
};
