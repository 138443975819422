import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import { CookiesInfo } from './CookiesInfo';

const COOKIES_POLICY_KEY = 'cp_approved';

export const CookiesInfoContainer = () => {
  const [isApproved, setApproved] = useState(!!cookies.get(COOKIES_POLICY_KEY));

  useEffect(() => {
    cookies.set(COOKIES_POLICY_KEY, isApproved ? '1' : '', {
      SameSite: 'lax',
      expires: 14,
      secure: window.location.protocol === 'https:',
    } as any);
  }, [isApproved]);

  const handleApprove = () => {
    setApproved(true);
  };

  return <CookiesInfo isApproved={isApproved} onApprove={handleApprove} />;
};
