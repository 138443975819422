import styled, { from } from 'theme';

export const MessageWrapper = styled.section`
  margin: 1rem auto;
  text-align: center;
  font-family: ${props => props.theme.variables.typography.secondaryFont};

  ${from('md')} {
    margin: 3rem auto;
  }
`;

export const MessageTitle = styled.h3`
  font-size: ${props => props.theme.variables.fonts.size.bigger2};
  margin-bottom: 1rem;
`;

export const MessageContent = styled.div`
  color: ${props => props.theme.variables.colors.midGrey};
`;
