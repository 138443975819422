import React from 'react';
import { Column } from 'shared';

import { LayoutWrapper } from './Layout.styles';
import { LayoutProps } from './Layout.types';

export const Layout = ({ header, footer, children }: LayoutProps) => (
  <LayoutWrapper className="layout" id="layout">
    {header && (
      <Column flexGrow="0" className="layout__header" style={{ flex: '0 0 auto' }}>
        {header}
      </Column>
    )}

    <Column className="layout__content" style={{ msFlex: '1 0 auto' }}>
      {children}
    </Column>

    {footer && (
      <Column flexGrow="0" className="layout__footer" style={{ flex: '0 0 auto' }}>
        {footer}
      </Column>
    )}
  </LayoutWrapper>
);
