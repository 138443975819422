import { Box } from 'shared';
import styled, { from } from 'theme';

export const FooterWrapper = styled(Box)`
  background-color: ${props => props.theme.variables.colors.lightGrey};
  flex: none;
  flex-flow: column;
`;

export const FooterGroups = styled.section`
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-top: 1.5rem;

  ${from('md')} {
    flex-flow: row;
    margin-bottom: 2rem;
    padding-top: 3rem;
  }
`;
