import styled, { from } from 'theme';

export const SectionWrapper = styled.section`
  padding: 1.5rem 0;

  ${from('md')} {
    padding: 2rem 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const SectionContent = styled.div``;

export const SectionTitle = styled.h3`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 2.25rem;
  text-align: center;
  margin-bottom: 1rem;

  ${from('md')} {
    font-size: ${props => props.theme.variables.fonts.size.bigger4};
    margin-bottom: 2rem;
  }
`;
