import styled from 'theme';

export const LinksGroupWrapper = styled.div<{ height: number | null }>`
  display: flex;
  ${props => (props.height ? `height: ${props.height}px` : undefined)};
  flex-flow: column;

  .footer__group__link {
    font-family: ${props => props.theme.variables.typography.secondaryFont};
    text-decoration: none;
    color: ${props => props.theme.variables.colors.black};
    line-height: 1.8rem;
    font-size: ${props => props.theme.variables.fonts.size.base};
    padding: 0.3rem 0;
    max-width: 75%;

    &:hover {
      color: ${props => props.theme.variables.colors.darkGrey};
      font-weight: ${props => props.theme.variables.fonts.weight.medium};
    }
  }
`;
