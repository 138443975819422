import styled, { from, to } from 'theme';

export const FormWrapper = styled.div`
  margin-bottom: 3.5rem;
`;

export const FormTitle = styled.div`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 2.25rem;
  color: ${props => props.theme.variables.customColors.textColor};
  margin: 0 7.0625rem;

  ${to('md')} {
    margin: 0 0.625rem;
  }
`;

export const FormSubTitle = styled.div`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 1.5rem;
  font-weight: ${props => props.theme.variables.fonts.weight.light};
  padding-top: 1rem;
  color: ${props => props.theme.variables.customColors.textColor};
  margin: 0 7.0625rem;
  line-height: 1.3;

  ${to('md')} {
    margin: 0 0.625rem;
  }
`;

export const FormContent = styled.div`
  margin: 1.75rem 9.375rem 0 9.375rem;

  .input {
    margin-bottom: 2rem;
  }

  .input--error {
    margin-bottom: 0.72rem;
  }

  .button:not(.input__clear-button) {
    margin-top: 2.5rem;
    padding: 0 1.875rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    ${from('md')} {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .input__chars-number,
  .input__max-number {
    top: -0.4rem;
    position: relative;
  }

  textarea {
    padding-right: 4.5rem !important;
  }

  ${to('md')} {
    margin: 0.625rem;
  }
`;
