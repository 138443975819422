import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, Dispatch } from 'redux';
import { setLocale } from 'store/state/locale/localeActions';

import { LocaleDropdown } from './LocaleDropdown';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeLocale: (locale: string) => dispatch(setLocale(locale)),
});

export const LocaleDropdownContainer = withRouter(
  compose(
    connect(
      undefined,
      mapDispatchToProps,
    ),
  )(LocaleDropdown),
);
