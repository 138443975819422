import React from 'react';

import { MessageContent, MessageTitle, MessageWrapper } from './Message.styles';
import { MessageProps } from './Message.types';

export const Message = ({ title, children }: MessageProps) => (
  <MessageWrapper className="message">
    <MessageTitle className="message__title">{title}</MessageTitle>
    <MessageContent className="message__content">{children}</MessageContent>
  </MessageWrapper>
);
