import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';
import { HomePageResponse } from 'store/api/cms/homePage/homePage.types';
import { fetchHomePage } from 'store/api/cms/homePage/homePageActions';

import { HomePage } from './HomePage';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchHomePage: () => dispatch<Promise<HomePageResponse>>(fetchHomePage()),
});

export const HomePageContainer = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(HomePage);
