import '@babel/polyfill';
import { GlobalStyle as BaseGlobalStyle } from '@melements/front-components';
import { NotificationsContextProvider } from '@melements/front-components/dist/context';
import * as Sentry from '@sentry/browser';
import { SentryBoundary } from 'app/sentry/SentryBoundary';
import { ModeContext } from 'context/mode/ModeContext';
import Cookies from 'js-cookie';
import React, { Fragment } from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import 'regenerator-runtime/runtime';
import { LocaleProvider } from 'shared/localeProvider/LocaleProvider';
import { createStore } from 'store/store';
import { ThemeProvider } from 'styled-components';
import { getThemeByMode } from 'theme/Theme';
import { getAppMode, getEnvironmentVar, getLocaleByDomain } from 'utils';

import { App } from './app/App';
import { GlobalStyle } from './index.styles';

if (getEnvironmentVar('REACT_APP_SENTRY_KEY') && getEnvironmentVar('REACT_APP_SENTRY_PROJECT')) {
  Sentry.init({
    dsn: `https://${getEnvironmentVar('REACT_APP_SENTRY_KEY')}@sentry.io/${getEnvironmentVar(
      'REACT_APP_SENTRY_PROJECT',
    )}`,
  });
}

const appMode = getAppMode();
const locale = getLocaleByDomain(appMode, Cookies.get('locale'));
const store = createStore({ locale, appMode });

ReactDOM.render(
  <SentryBoundary>
    <Provider store={store}>
      <ThemeProvider theme={getThemeByMode(appMode)}>
        <ModeContext.Provider value={{ mode: appMode }}>
          <LocaleProvider locale={locale}>
            <GoogleReCaptchaProvider reCaptchaKey={getEnvironmentVar('REACT_APP_RECAPTCHA_KEY') as string}>
              <NotificationsContextProvider>
                <Fragment>
                  <BaseGlobalStyle />
                  <GlobalStyle />
                  <App />
                </Fragment>
              </NotificationsContextProvider>
            </GoogleReCaptchaProvider>
          </LocaleProvider>
        </ModeContext.Provider>
      </ThemeProvider>
    </Provider>
  </SentryBoundary>,
  document.getElementById('developer-portal'),
);
