import styled from 'theme';

export const MarkdownContentWrapper = styled.div`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 0.937rem;
  line-height: 1.6;

  ul,
  ol {
    li {
      margin-left: 1rem;
    }
  }

  h1 {
    font-size: ${props => props.theme.variables.fonts.size.bigger4};
    margin-bottom: 2rem;
    line-height: normal;
  }

  h3 {
    font-size: ${props => props.theme.variables.fonts.size.bigger2};
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: ${props => props.theme.variables.fonts.weight.bold};
  }

  p,
  ul {
    padding: 0 0 1rem;

    &:last-child {
      padding: 0;
    }
  }

  strong {
    font-weight: ${props => props.theme.variables.fonts.weight.bold};
  }

  a {
    color: ${props => props.theme.variables.colors.red};

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    background-color: white;
    padding: 1rem;
    margin: 0 0 1rem;

    &:last-child {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
    max-height: 30rem;
  }
`;
