import React from 'react';
import { Column, FaqGroupedContent, Loader, LocaleContext, Message, Query, Row } from 'shared';

import { FAQProps } from './FAQ.types';

export const FAQ = ({ onFetchFAQ }: FAQProps) => (
  <LocaleContext.Consumer>
    {({ formatMessage }) => (
      <Query onFetch={onFetchFAQ}>
        {({ error, loading, response }) => (
          <Row height="100%">
            <Column>
              {loading && <Loader />}

              {error && (
                <Message title={formatMessage({ id: 'LIST.ERROR.TITLE' })}>
                  {formatMessage({ id: 'LIST.ERROR.CONTENT' })}
                </Message>
              )}

              {!loading && response && response.payload.length === 0 && (
                <Message title={formatMessage({ id: 'LIST.EMPTY.TITLE' })}>
                  {formatMessage({ id: 'LIST.EMPTY.CONTENT' })}
                </Message>
              )}

              {!loading && response && response.payload.length > 0 && <FaqGroupedContent groups={response.payload} />}
            </Column>
          </Row>
        )}
      </Query>
    )}
  </LocaleContext.Consumer>
);
