import { LocaleContextProvider } from '@melements/front-components/dist/context';
import * as localeData from 'locales';
import React, { useState } from 'react';
import { addLocaleData, injectIntl, IntlProvider } from 'react-intl';
import cs from 'react-intl/locale-data/cs';
import en from 'react-intl/locale-data/en';
import pl from 'react-intl/locale-data/pl';

import { LocaleProviderProps } from './LocaleProvider.types';

const locales = (localeData as any).default;

addLocaleData([...en]);
addLocaleData([...pl]);
addLocaleData([...cs]);

const WrappedLocaleContext = injectIntl(LocaleContextProvider);

export const LocaleProvider = (props: LocaleProviderProps) => {
  const [locale, setLocale] = useState(props.locale);
  const messages = locales[locale];

  const updateLocale = (newLocale: string) => {
    setLocale(newLocale);
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <WrappedLocaleContext updateLocale={updateLocale} locale={locale}>
        {props.children}
      </WrappedLocaleContext>
    </IntlProvider>
  );
};
