import React from 'react';
import { Link } from 'shared';

import { HeaderNavWrapper } from './HeaderNav.styles';
import { HeaderNavProps } from './HeaderNav.types';
import { HeaderSubNav } from './headerSubNav/HeaderSubNav';

export const HeaderNav = ({
  active,
  links,
  onClickLink,
  isMobileOpened,
  onOpenSubMenu,
  subMenuOpened,
}: HeaderNavProps) => {
  const handleOpenSubMenu = (index: number) => () => {
    onOpenSubMenu(index);
  };

  const handleClickBackToTopMenu = () => {
    onOpenSubMenu(null);
  };

  return (
    <HeaderNavWrapper className="header__nav">
      <ul className="header__nav-list">
        {links.map((link, index) => (
          <li className="header__nav-item" key={index}>
            <Link
              className={`header__nav-link ${active === link.url ? 'header__nav-link--active' : ''} ${
                link.subLinks && link.subLinks.length ? 'header__nav-link--parent' : ''
              }`}
              onClick={link.subLinks && link.subLinks.length > 0 ? handleOpenSubMenu(index) : onClickLink}
              {...link}
            />

            {link.subLinks && link.subLinks.length > 0 && (
              <HeaderSubNav
                active={active}
                links={link.subLinks}
                isOpen={subMenuOpened === index && isMobileOpened}
                onClickLink={onClickLink}
                onClickBack={handleClickBackToTopMenu}
              />
            )}
          </li>
        ))}
      </ul>
    </HeaderNavWrapper>
  );
};
