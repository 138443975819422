import { useQuery } from 'shared/hooks/useQuery/useQuery';

import { QueryProps } from './Query.types';

type QueryType = <T extends { error: boolean }>(p: QueryProps<T>) => any;

export const Query: QueryType = ({ children, onFetch, ...props }) => {
  const { error, loading, handleFetch, response } = useQuery(onFetch);

  return children({
    error,
    handleFetch,
    loading,
    response,
    ...props,
  });
};
