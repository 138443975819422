import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';
import { FAQResponse } from 'store/api/cms/faq/faq.types';
import { fetchFAQ } from 'store/api/cms/faq/faqActions';

import { FAQ } from './FAQ';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchFAQ: () => dispatch<Promise<FAQResponse>>(fetchFAQ()),
});

export const FAQContainer = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(FAQ);
