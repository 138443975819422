import { useEffect, useState } from 'react';

import { PageWidthProps } from './PageWidth.interface';

type PageWidthType = (p: PageWidthProps) => any;

export const PageWidth: PageWidthType = ({ children }) => {
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    const developerPortal = document.getElementById('developer-portal');
    setPageWidth((developerPortal && developerPortal.offsetWidth) || 0);
  }, []);

  return children({
    pageWidth,
  });
};
