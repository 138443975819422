import React from 'react';
import { Column } from 'shared';

import { ProductBlock } from './productBlock/ProductBlock';
import { ProductsWrapper } from './Products.styles';
import { ProductsProps } from './Products.types';

export const Products = ({ products, tiny }: ProductsProps) => (
  <ProductsWrapper className="products">
    {products.map((product, index) => (
      <Column key={index} size={[12, 6, 4]} className="products__column">
        <ProductBlock {...product} tiny={tiny} />
      </Column>
    ))}
  </ProductsWrapper>
);
