import { Middleware } from 'redux';
import { Action, Dispatch, isRSAA, RSAA } from 'redux-api-middleware';
import { CMS_API } from 'store/api';
import { State } from 'store/state/state.types';
import { ModeType } from 'theme/Theme.types';

export const createModeMiddleware = (appMode: ModeType): Middleware<{}, State, Dispatch> => store => (
  next: Dispatch<Action>,
) => (action: Action) => {
  if (!isRSAA(action)) {
    return next(action);
  }

  const apiCall = action[RSAA];
  const localeState = store.getState().locale;

  return next({
    [RSAA]: {
      ...apiCall,
      endpoint:
        apiCall.api === CMS_API
          ? `${apiCall.endpoint}?locale=${localeState ? localeState.locale : ''}`
          : apiCall.endpoint,
      headers:
        typeof apiCall.headers !== 'function' && apiCall.api === CMS_API
          ? {
              ...apiCall.headers,
              mode: `${appMode}_${localeState && localeState.locale ? localeState.locale.toUpperCase() : ''}`,
            }
          : apiCall.headers,
    },
  });
};
