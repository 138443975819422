import { ModeType } from 'theme/Theme.types';
import { getAvailableLanguages } from 'utils/getAvailableLanguages/getAvailableLanguages';

const detectDomainLocale = () => {
  if (window.location.host.includes('.pl')) {
    return 'pl';
  }

  return 'en';
};

const isLocaleIncludedInAvailable = (mode: string, locale: string) =>
  getAvailableLanguages(mode).filter(l => l.key === locale).length > 0;

export const getLocaleByDomain = (mode: ModeType, currentLocale?: string) => {
  if (currentLocale && isLocaleIncludedInAvailable(mode, currentLocale)) {
    return currentLocale;
  }

  const domainLocale = detectDomainLocale();

  if (isLocaleIncludedInAvailable(mode, domainLocale)) {
    return domainLocale;
  }

  return 'en';
};
