import React from 'react';
import { Link } from 'shared';

import { FB, FooterBottomLinks, FooterBottomSocial, FooterBottomWrapper } from './FooterBottom.styles';
import { FooterBottomProps } from './FooterBottom.types';

export const FooterBottom = ({ links }: FooterBottomProps) => (
  <FooterBottomWrapper className="footer__bottom">
    ©2019 mBank S.A.
    <FooterBottomLinks className="footer__bottom__links">
      {links.map((link, index) => (
        <Link className="footer__bottom__link" key={index} {...link} />
      ))}
    </FooterBottomLinks>
    <FooterBottomSocial className="footer__social">
      <FB href="https://www.facebook.com/mBank.Polska/" target="_blank" />
    </FooterBottomSocial>
  </FooterBottomWrapper>
);
