import styled, { from, to } from 'theme';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: ${props => props.theme.variables.zIndex.zIndexModalContainer};
  overflow-y: auto;
`;

export const ModalContainer = styled.div`
  position: absolute;
  background-color: ${props => props.theme.variables.colors.white};
  background-color: ${props => props.theme.variables.customColors.modal.backgroundColor};
  border-radius: 0.1875rem;
  box-shadow: 0 0.0625rem 0.75rem 0 rgba(0, 0, 0, 0.22), 0 0 0.0625rem 0 rgba(0, 0, 0, 0.32);
  top: 8.25rem;

  ${to('md')} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
  }

  ${from('md')} {
    left: 5vw;
    right: 5vw;
  }

  ${from('lg')} {
    left: 20vw;
    right: 20vw;
  }

  ${from('xlg')} {
    left: calc((100vw - ${props => props.theme.variables.breakpoints.xlg}px) / 2 + 347px);
    right: calc((100vw - ${props => props.theme.variables.breakpoints.xlg}px) / 2 + 366px);
  }
`;

export const ModalHeader = styled.div`
  padding: 0.75rem;
  display: flex;
`;
