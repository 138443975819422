import React, { Fragment, useContext } from 'react';
import {
  AutoDetectLocaleChange,
  Column,
  FooterBlock,
  HeaderBlock,
  Loader,
  LocaleContext,
  MarkdownContent,
  Message,
  News,
  Products,
  Query,
  Row,
  Section,
} from 'shared';
import { adaptApiLink, getApiResourceUrl } from 'utils';

import { SectionText } from './HomePage.styles';
import { HomePageProps } from './HomePage.types';

export const HomePage = ({ onFetchHomePage }: HomePageProps) => {
  const context = useContext(LocaleContext);

  return (
    <Query onFetch={onFetchHomePage}>
      {({ error, loading, response, handleFetch }) => (
        <Fragment>
          <AutoDetectLocaleChange onChange={handleFetch} />

          {loading && <Loader />}

          {error && (
            <Message title={context.formatMessage({ id: 'LIST.ERROR.TITLE' })}>
              {context.formatMessage({ id: 'LIST.ERROR.CONTENT' })}
            </Message>
          )}

          {!loading && response && (
            <Fragment>
              {response.payload.banners &&
                response.payload.banners.map(
                  banner =>
                    banner.type === 'HERO' && (
                      <Row key={banner.id}>
                        <Column mx={[1, 2]} mt={[1, 1, 0]}>
                          <HeaderBlock
                            description={banner.content}
                            image={banner.image ? getApiResourceUrl(banner.image) : ''}
                            link={banner.link ? adaptApiLink(banner.link) : undefined}
                            title={banner.title}
                            overlayColor={banner.overlayColor}
                          />
                        </Column>
                      </Row>
                    ),
                )}

              <Row>
                <Column mx={[1, 2, 2, 6]} mt={[1, '2rem']} mb={[0, '2rem']}>
                  {response.payload.sections &&
                    response.payload.sections.map(section => (
                      <Section title={section.title} key={section.id}>
                        {section.description && (
                          <SectionText>
                            <MarkdownContent>{section.description}</MarkdownContent>
                          </SectionText>
                        )}

                        {section.type === 'API' && (
                          <Products
                            products={section.blocks.map(block => ({
                              description: block.content,
                              icon: block.image ? getApiResourceUrl(block.image) : '',
                              links: block.links.map(link => adaptApiLink(link)),
                              title: block.title,
                            }))}
                          />
                        )}

                        {section.type === 'NEWS' && (
                          <News
                            news={section.blocks.map(block => ({
                              description: block.content,
                              image: block.image ? getApiResourceUrl(block.image) : '',
                              links: block.links.map(link => adaptApiLink(link)),
                              title: block.title,
                            }))}
                          />
                        )}
                      </Section>
                    ))}

                  {response.payload.banners &&
                    response.payload.banners.map(
                      banner =>
                        banner.type === 'FOOTER' && (
                          <Section key={banner.id}>
                            <FooterBlock
                              description={banner.content}
                              image={banner.image ? getApiResourceUrl(banner.image) : ''}
                              link={banner.link ? adaptApiLink(banner.link) : undefined}
                              title={banner.title}
                            />
                          </Section>
                        ),
                    )}
                </Column>
              </Row>
            </Fragment>
          )}
        </Fragment>
      )}
    </Query>
  );
};
