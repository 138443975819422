import styled, { from } from 'theme';

export const ReportPageSection = styled.section`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

export const ReportPageText = styled.div`
  padding: 1rem 1rem;
  display: flex;
  flex-flow: column;
  text-align: center;
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 1rem;

  ${from('md')} {
    padding: 3rem 15rem;
    font-size: 1.5rem;
  }
`;

export const ReportList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Report = styled.div`
  padding: 3rem 2rem 6rem 2rem;
  width: 90%;
  margin: auto;
  display: flex;
  flex-flow: column;

  ${from('md')} {
    width: 25%;
  }

  .button__download {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.variables.colors.red};
  }
`;

export const ReportIcon = styled.div`
  height: 6rem;
  width: 6rem;
  margin: auto;
  background: url(${props => props.theme.variables.icons.svg.reportIcon});
`;

export const ReportName = styled.span`
  width: 12rem;
  margin: 0.75rem auto;
  text-align: center;
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 1.3125rem;
`;
