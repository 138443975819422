import React from 'react';
import { Column, Row } from 'shared';

import { FooterGroups, FooterWrapper } from './Footer.styles';
import { FooterProps } from './Footer.types';
import { FooterBottom } from './footerBottom/FooterBottom';
import { FooterGroup } from './footerGroup/FooterGroup';

export const Footer = ({ groups, links }: FooterProps) => (
  <Row>
    <Column mx={[0, 0, 0, 2]}>
      <FooterWrapper className="footer" px={[1, 2, 2, 5]} noMargin={true}>
        {groups.length > 0 && (
          <FooterGroups className="footer__groups">
            {groups.map((group, index) => (
              <FooterGroup {...group} key={index} />
            ))}
          </FooterGroups>
        )}

        <FooterBottom links={links} />
      </FooterWrapper>
    </Column>
  </Row>
);
