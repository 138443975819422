import styled from 'theme';

export const HeaderNavToggleWrapper = styled.div`
  width: 3.5rem;
  height: 3.125rem;
  padding: 0;
  cursor: pointer;
  position: relative;
`;

export const HeaderNavToggleContent = styled.span`
  width: 1.4375rem;
  top: 1.5rem;
  left: 1.135rem;
  background: ${props => props.theme.variables.customColors.leadingColor.base};
  transition: all 500ms ease-in-out;
  border-radius: ${props => props.theme.variables.borders.width.base};
  position: absolute;
  display: block;
  height: 0.125rem;
  cursor: pointer;
  content: '';

  &::before,
  &::after {
    background: ${props => props.theme.variables.customColors.leadingColor.base};
    width: 1.4375rem;
    height: 0.125rem;
    transition: all 500ms ease-in-out;
    border-radius: ${props => props.theme.variables.borders.width.base};
    position: absolute;
    display: block;
    cursor: pointer;
    content: '';
  }

  &::before {
    top: 0.4375rem;
  }

  &::after {
    top: -0.4375rem;
  }

  &.header__nav-toggle__content--opened {
    background-color: transparent;

    &::before,
    &::after {
      top: 0;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
`;
