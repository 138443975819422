import React, { Fragment } from 'react';
import { AutoDetectLocaleChange, Footer as FooterBase, Query } from 'shared';
import { adaptApiLink } from 'utils';

import { FooterProps } from './Footer.types';

export const Footer = ({ onFetchBottomNavigation }: FooterProps) => (
  <Query onFetch={onFetchBottomNavigation}>
    {({ error, loading, response, handleFetch }) => (
      <Fragment>
        <AutoDetectLocaleChange onChange={handleFetch} />
        <FooterBase
          links={!loading && !error && response ? response.payload.map(l => adaptApiLink(l)) : []}
          groups={[]}
        />
      </Fragment>
    )}
  </Query>
);
