import { RefObject, useEffect, useState } from 'react';

export const useHeight: (ref: RefObject<HTMLDivElement>) => number | null = ref => {
  const [height, setHeight] = useState<null | number>(null);
  const current = ref.current;

  useEffect(() => {
    setHeight(current ? current.scrollHeight : null);
  }, [setHeight, current]);

  return height;
};
