import styled, { from } from 'theme';

export const ProductBlockWrapper = styled.section<{ tiny?: boolean }>`
  padding: ${props => (props.tiny ? '1.625rem 1.25rem 1.625rem' : '2.625rem 2.25rem 1.625rem')};
  margin-bottom: 1.5rem;
  display: flex;
  flex-flow: column;
  border: ${props => props.theme.variables.borders.width.bigger} solid
    ${props => props.theme.variables.customColors.boxBorder};
  width: 100%;

  ${from('md')} {
    padding: ${props => (props.tiny ? '2.625rem 1.25rem 1.625rem' : '2.625rem 3rem 1.625rem')};
  }

  &:hover {
    background-color: ${props => props.theme.variables.colors.lightGrey};
  }

  .product__description {
    margin-bottom: ${props => (props.tiny ? '1rem' : '2rem')};

    ${from('md')} {
      margin-bottom: ${props => (props.tiny ? '1rem' : '3rem')};
    }
  }

  .product__link {
    font-family: ${props => props.theme.variables.typography.secondaryFont};
    font-weight: ${props => props.theme.variables.fonts.weight.bold};
    font-size: ${props => props.theme.variables.fonts.size.base};
    color: ${props => props.theme.variables.customColors.leadingColor.base};
    text-transform: uppercase;
    text-align: center;

    &:hover {
      color: ${props => props.theme.variables.customColors.leadingColor.mid};
    }
  }
`;

export const ProductIcon = styled.img`
  margin: 0 auto 3rem;
  width: 11.25rem;
  height: 7.5rem;
`;

export const ProductTitle = styled.h3`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-weight: ${props => props.theme.variables.fonts.weight.bold};
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
`;

export const ProductDescription = styled.span`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: ${props => props.theme.variables.fonts.size.smaller0};
  text-align: center;
  margin-bottom: 3rem;
  line-height: 1.4;
  flex: 1 1 auto;
`;
