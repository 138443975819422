import React, { useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { useOnClickOutside } from 'shared/hooks';

import { ArrowDown, DropdownToogle } from './Dropdown.styles';
import { DropdownProps } from './Dropdown.types';
import { DropdownPopup } from './DropdownPopup/DropdownPopup';
import { DropdownElementProps } from './DropdownPopup/DropdownPopup.types';

export const Dropdown = ({ onClick, items, children }: DropdownProps) => {
  const containerRef = useRef(null);
  const [isOpened, setOpened] = useState(false);

  useOnClickOutside(containerRef, () => {
    setOpened(false);
  });

  const handleSelectClick = () => {
    setOpened(current => !current);
  };

  const handleClick = (item: DropdownElementProps) => {
    setOpened(false);
    onClick(item);
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <DropdownToogle ref={ref} onClick={handleSelectClick} className="dropdown__toggle">
            {children}
            <ArrowDown />
          </DropdownToogle>
        )}
      </Reference>
      {isOpened && (
        <Popper placement="bottom-end" positionFixed={true}>
          {({ ref, style, placement }) => (
            <div ref={containerRef}>
              <DropdownPopup innerRef={ref} style={style} placement={placement} items={items} onClick={handleClick} />
            </div>
          )}
        </Popper>
      )}
    </Manager>
  );
};
