import styled, { from } from 'theme';

export const SectionText = styled.div`
  font-size: 0.9375rem;
  line-height: 1.6;

  ${from('lg')} {
    max-width: 40rem;
  }
`;

export const SectionTeaser = styled.h3`
  font-size: 1.5rem;
  font-weight: ${props => props.theme.variables.fonts.weight.light};
  line-height: 1.25;
  margin-bottom: 2rem;
`;
