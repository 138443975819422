type Locale = {
  key: string;
  label: string;
  browserKey: string;
};

const locales = {
  CORPORATION: [
    {
      browserKey: 'en',
      key: 'en',
      label: 'EN',
    },
    {
      browserKey: 'pl',
      key: 'pl',
      label: 'PL',
    },
  ],
  INDIVIDUAL: [
    {
      browserKey: 'en',
      key: 'en',
      label: 'EN',
    },
    {
      browserKey: 'pl',
      key: 'pl',
      label: 'PL',
    },
  ],
};

export const getAvailableLanguages: (mode: string) => Locale[] = mode => locales[mode] || [];
