import { Box } from 'shared';
import styled from 'theme';

export const PageBackgroundBox = styled(Box)`
  background-color: ${props => props.theme.variables.colors.lightGrey};
  margin-bottom: 0;
  height: 100%;
`;

export const BoxWrapper = styled(Box)`
  h1 {
    font-size: ${props => props.theme.variables.fonts.size.bigger4};
    font-family: ${props => props.theme.variables.typography.secondaryFont};
    margin-bottom: 2rem;
    line-height: normal;
  }
`;
