import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';

import { ReportPageResponse } from '../../store/api/cms/reportPage/reportPage.types';
import { fetchReportPage } from '../../store/api/cms/reportPage/reportPageActions';

import { ReportPage } from './ReportPage';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchReportPage: () => dispatch<Promise<ReportPageResponse>>(fetchReportPage()),
});

export const ReportPageContainer = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(ReportPage);
