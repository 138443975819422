import styled, { from } from 'theme';

export const HeaderNotificationContainer = styled.div`
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #046eb1;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
`;

export const HeaderNotificationContent = styled.div`
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: inherit;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.75rem 0 0.75rem 0;
  width: 100%;

  ${from('lg')} {
    font-size: 12px;
    line-height: 2.08;
  }
`;

export const NotificationButtonWrapper = styled.div`
  padding-left: 0.25rem;
  button {
    min-height: 1.5rem;
    min-width: 1.5rem;
    div {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  ${from('lg')} {
    padding-left: 0.5rem;
  }
`;
