import { FormApi } from 'final-form';
import React, { useContext, useState } from 'react';
import { AlertBox, Column, HeaderBlock, Link, LocaleContext, Question, Row } from 'shared';
import { CreateSupportIssuePayload } from 'store/api/portal/support/support.types';
import { getEnvironmentVar, getErrorMessage } from 'utils';

import { ContactForm } from './contactForm/ContactForm';
import { BoldedFragment, ChevronLink, ContactInfo, ContactPageWrapper, FooterDescription } from './ContactPage.styles';
import { ContactPageProps } from './ContactPage.types';

export const ContactPage = ({ onCreateSupportIssue }: ContactPageProps) => {
  const [isSubmitFailed, setSubmitFailed] = useState(false);
  const [failMessageId, setFailMessageId] = useState('');
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const localeContext = useContext(LocaleContext);
  const formatMessage = localeContext.formatMessage;

  const handleSubmit = async (values: CreateSupportIssuePayload, formApi: FormApi) => {
    try {
      return await onCreateSupportIssue(values, formApi, () => {
        setSubmitSuccess(true);
      });
    } catch (error) {
      if (error.payload && error.payload.response && error.payload.response.code) {
        setFailMessageId(getErrorMessage(error.payload.response.code));
      }

      setSubmitFailed(true);
    }
  };

  return (
    <ContactPageWrapper>
      <Row>
        <Column mx={[1, 2]} mt={[1, 1, 0]}>
          <HeaderBlock
            image={require('assets/images/banner.jpg')}
            title={formatMessage({ id: 'CONTACTPAGE.TITLE' })}
            overlayColor="rgba(0, 47, 105, 0.8)"
          />
        </Column>
      </Row>
      <Row>
        <Column mx={[1, 2, 2, 6]} mt={[1, '3.875rem']}>
          <Row>
            <Column size={[12]}>
              <Row>
                <Column size={[12, 12, 6]}>
                  {!isSubmitSuccess && !isSubmitFailed && <ContactForm onSubmit={handleSubmit} />}

                  {isSubmitSuccess && (
                    <AlertBox
                      type={AlertBox.types.SUCCESS}
                      title={formatMessage({ id: 'CONTACTPAGE.FORM.SUCCESS.TITLE' })}
                    >
                      {formatMessage({ id: 'CONTACTPAGE.FORM.SUCCESS.CONTENT' })}
                    </AlertBox>
                  )}

                  {isSubmitFailed && (
                    <AlertBox type={AlertBox.types.FAIL} title={formatMessage({ id: 'CONTACTPAGE.FORM.FAIL.TITLE' })}>
                      {formatMessage({ id: 'CONTACTPAGE.FORM.FAIL.CONTENT' })}

                      {!!failMessageId && (
                        <span>
                          <br />
                          {formatMessage({ id: failMessageId })}
                        </span>
                      )}
                    </AlertBox>
                  )}
                </Column>
                <Column size={[12, 12, 6]} className="contact-intro">
                  <BoldedFragment>{formatMessage({ id: 'CONTACTPAGE.QUESTIONS' })}</BoldedFragment>
                  <ChevronLink>
                    <Link external={true} target="_blank" url={`${getEnvironmentVar('REACT_APP_JSD_URL')}`}>
                      {formatMessage({ id: 'CONTACTPAGE.CREATE.AN.ISSUE' })}
                    </Link>
                  </ChevronLink>
                  <ChevronLink>
                    {formatMessage({ id: 'CONTACTPAGE.EMAIL' })}
                    <Link external={true} url={`mailto: ${formatMessage({ id: 'CONTACTPAGE.EMAIL.LINK' })}`}>
                      {formatMessage({ id: 'CONTACTPAGE.EMAIL.LINK' })}
                    </Link>
                  </ChevronLink>
                  <ContactInfo
                    className="contact__info"
                    dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'CONTACTPAGE.DESCRIPTION' }) }}
                  />
                  <BoldedFragment className="contact__email">
                    {formatMessage({ id: 'CONTACTPAGE.WRITE.TO.US' })}
                  </BoldedFragment>
                </Column>
              </Row>

              <Question
                title={formatMessage({ id: 'CONTACTPAGE.AVAILABILITY.TITLE' })}
                answer={formatMessage({ id: 'CONTACTPAGE.AVAILABILITY.DESCRIPTION' })}
              />
              <Question
                title={formatMessage({ id: 'CONTACTPAGE.BREAKS.TITLE' })}
                answer={formatMessage({ id: 'CONTACTPAGE.BREAKS.DESCRIPTION' })}
              />
              <Question
                title={formatMessage({ id: 'CONTACTPAGE.ISSUES.TITLE' })}
                answer={formatMessage({ id: 'CONTACTPAGE.ISSUES.DESCRIPTION' })}
              />

              <FooterDescription className="contact-footer__description">
                {formatMessage({ id: 'CONTACTPAGE.FOOTER.DESCRIPTION' })}
              </FooterDescription>
            </Column>
          </Row>
        </Column>
      </Row>
    </ContactPageWrapper>
  );
};
