import { Resource } from 'store/api/cms/types/response.types';
import { getEnvironmentVar } from 'utils/getEnvironmentVar/getEnvironmentVar';

export const getApiResourceUrl = (resource: Resource) => {
  if (resource.provider && resource.provider === 'local') {
    if (getEnvironmentVar('REACT_APP_CMS_API_URL') === '/') {
      return window.location.origin + resource.url;
    }

    return `${getEnvironmentVar('REACT_APP_CMS_API_URL')}${resource.url}`;
  }

  return resource.url;
};
