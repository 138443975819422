import styled from 'theme';

export const QuestionExpandButton = styled.div`
  transition: all 0.4s;
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 1.5rem;
  cursor: pointer;
  background-image: url(${props => props.theme.variables.icons.svg.chevronDown});
  background-size: cover;
`;

export const QuestionTitle = styled.span<{ collapsed: boolean }>`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-weight: ${props =>
    props.collapsed ? props.theme.variables.fonts.weight.light : props.theme.variables.fonts.weight.medium};
  font-size: 1.25rem;
  line-height: 1.25;
  display: flex;

  ${QuestionExpandButton} {
    margin-left: 1rem;
    transform: ${props => (props.collapsed ? 'rotate(0deg)' : 'rotate(-180deg)')};
  }
`;

export const QuestionAnswer = styled.span`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 0.9375rem;
  padding-top: 1.25rem;
  line-height: 1.6;
`;
