import React from 'react';
import { Button, Link } from 'shared';

import {
  HeaderBlockDescription,
  HeaderBlockTitle,
  HeaderBlockWrapper,
  HeaderBlockWrapperLayer,
} from './HeaderBlock.styles';
import { HeaderBlockProps } from './HeaderBlock.types';

export const HeaderBlock = ({ title, description, image, link, overlayColor }: HeaderBlockProps) => {
  return (
    <HeaderBlockWrapper style={{ backgroundImage: `url(${image})` }} className="header-block">
      <HeaderBlockWrapperLayer className="header-block__layer" overlayColor={overlayColor}>
        <HeaderBlockTitle className="header-block__title">{title}</HeaderBlockTitle>
        {description && (
          <HeaderBlockDescription className="header-block__description">{description}</HeaderBlockDescription>
        )}
        {link && (
          <Button as={Link} {...link} className="header-block__link">
            {link.label}
          </Button>
        )}
      </HeaderBlockWrapperLayer>
    </HeaderBlockWrapper>
  );
};
