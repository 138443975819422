import styled, { to } from 'theme';

export const DropdownContainer = styled.div`
  padding: 0.5rem 0 0.25rem 0;
  background-color: ${props => props.theme.variables.colors.white};
  border-radius: 0.1875rem;
  margin-top: 0.4375rem;
  box-shadow: 0 0.0625rem 1rem 0 rgba(0, 0, 0, 0.22), 0 0 0.0625rem 0 rgba(0, 0, 0, 0.32);

  ${to('md')} {
    width: 100vw;
    top: 0.5rem !important;
    left: -0.325rem !important;
  }
`;
export const DropdownElement = styled.div`
  padding: 0.75rem 2rem;
  width: 4.25rem;
  background-color: white;
  color: ${props => props.theme.variables.colors.black};
  font-family: ${props => props.theme.variables.typography.primaryFont};
  font-size: ${props => props.theme.variables.fonts.size.smaller0};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.variables.customColors.lightGrey};
  }

  ${to('md')} {
    width: 100vw;
  }
`;
