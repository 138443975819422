import React, { useContext, useState } from 'react';
import { Field, FieldRenderProps, Form, FormRenderProps } from 'react-final-form';
import { Button, Column, Input, LocaleContext, RefreshingReCaptcha, Row, Textarea } from 'shared';
import { combineValidators, required as requiredValidator, validateEmail } from 'utils/formValidators';

import { ContactFormWrapper, ReCaptchaPolicy } from './ContactForm.styles';
import { ContactFormProps } from './ContactForm.types';

export const ContactForm = ({ onSubmit }: ContactFormProps) => {
  const localeContext = useContext(LocaleContext);
  const [isCaptchaFilled, setCaptchaFilled] = useState(false);
  const required = requiredValidator(localeContext.formatMessage({ id: 'FORM.REQUIRED' }));

  const handleVerifyCaptcha = (change: (name: string, value?: any) => void) => (response: string) => {
    change('captchaResponse', response);
    setCaptchaFilled(true);
  };

  return (
    <ContactFormWrapper className="contact-form">
      <Form onSubmit={onSubmit} validateOnBlur={false}>
        {({ handleSubmit, submitting, form: { change } }: FormRenderProps) => {
          return (
            <form onSubmit={handleSubmit} action="">
              <Row>
                <Column size={[6]}>
                  <Field name="name" validate={required}>
                    {({ input, meta }: FieldRenderProps<HTMLInputElement>) => (
                      <Input
                        label={localeContext.formatMessage({ id: 'CONTACTPAGE.FORM.NAME' })}
                        input={input}
                        meta={meta}
                        className="contact-form__name"
                        maxLenght={100}
                        clearable={true}
                      />
                    )}
                  </Field>
                </Column>
                <Column size={[6]}>
                  <Field
                    name="email"
                    validate={combineValidators(
                      required,
                      validateEmail(localeContext.formatMessage({ id: 'FORM.INVALID-EMAIL' })),
                    )}
                  >
                    {({ input, meta }: FieldRenderProps<HTMLInputElement>) => (
                      <Input
                        label={localeContext.formatMessage({ id: 'CONTACTPAGE.FORM.E-MAIL' })}
                        input={input}
                        meta={meta}
                        className="contact-form__email"
                        maxLenght={100}
                        clearable={true}
                      />
                    )}
                  </Field>
                </Column>
              </Row>

              <Field name="subject" validate={required}>
                {({ input, meta }: FieldRenderProps<HTMLInputElement>) => (
                  <Input
                    label={localeContext.formatMessage({ id: 'CONTACTPAGE.FORM.SUBJECT' })}
                    input={input}
                    meta={meta}
                    className="contact-form__subject"
                    maxLenght={100}
                    charsCounter={true}
                  />
                )}
              </Field>

              <Field name="description" validate={required}>
                {({ input, meta }: FieldRenderProps<HTMLTextAreaElement>) => (
                  <Textarea
                    label={localeContext.formatMessage({ id: 'CONTACTPAGE.FORM.MESSAGE' })}
                    input={input}
                    meta={meta}
                    className="contact-form__description"
                    maxLenght={1000}
                    charsCounter={true}
                  />
                )}
              </Field>
              <RefreshingReCaptcha refreshTime={90000} onVerify={handleVerifyCaptcha(change)} />
              <ReCaptchaPolicy>
                This site is protected by reCAPTCHA and the Google{' '}
                <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{' '}
                apply.
              </ReCaptchaPolicy>

              <Button className="contact-form__submit" disabled={submitting || !isCaptchaFilled}>
                {localeContext.formatMessage({ id: 'CONTACTPAGE.FORM.SEND' })}
              </Button>
            </form>
          );
        }}
      </Form>
    </ContactFormWrapper>
  );
};
