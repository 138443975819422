import React from 'react';
import { Collapse, MarkdownContent } from 'shared';

import { QuestionAnswer, QuestionExpandButton, QuestionTitle } from './Question.styles';
import { QuestionProps } from './Question.types';

export const Question = ({ title, answer }: QuestionProps) => {
  const titleRender = (collapsed: boolean) => (
    <QuestionTitle className="question__title" collapsed={collapsed}>
      {title} <QuestionExpandButton />
    </QuestionTitle>
  );

  return (
    <Collapse className="question" title={titleRender}>
      <QuestionAnswer className="question__answer">
        <MarkdownContent>{answer}</MarkdownContent>
      </QuestionAnswer>
    </Collapse>
  );
};
