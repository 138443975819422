import React from 'react';

import { DropdownContainer, DropdownElement } from './DropdownPopup.styles';
import { DropdownElementProps, DropdownProps } from './DropdownPopup.types';

export const DropdownPopup = ({ items, placement, style, innerRef, onClick }: DropdownProps) => {
  const handleClick = (item: DropdownElementProps) => () => onClick(item);

  return (
    <DropdownContainer className="dropdown__container" ref={innerRef} style={{ ...style }} data-placement={placement}>
      {items.map((item: DropdownElementProps) => (
        <DropdownElement key={item.key} onClick={handleClick(item)} className="dropdown__element">
          {item.label}
        </DropdownElement>
      ))}
    </DropdownContainer>
  );
};
