import styled, { to } from 'theme';

export const AlertBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.5rem;

  ${to('md')} {
    padding: 0 0.625rem;
  }
`;

const AlertBoxIcon = styled.div`
  height: 6.625rem;
  width: 6.625rem;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;

  ${to('md')} {
    margin-top: 0.625rem;
    margin-bottom: 1.875rem;
  }
`;

export const AlertBoxSuccessIcon = styled(AlertBoxIcon)`
  background: url(${props => props.theme.variables.icons.svg.successIcon});
`;

export const AlertBoxFailIcon = styled(AlertBoxIcon)`
  background: url(${props => props.theme.variables.icons.svg.failIcon});
`;

export const AlertBoxTitle = styled.div`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-size: 2.25rem;
  color: ${props => props.theme.variables.customColors.textColor};
  margin-bottom: 0.625rem;
  text-align: center;
`;

export const AlertBoxContent = styled.div`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  font-weight: ${props => props.theme.variables.fonts.weight.light};
  font-size: 1.5rem;
  color: ${props => props.theme.variables.customColors.secondaryTextColor};
  line-height: 2rem;
  width: 70%;
  text-align: center;
  white-space: pre-wrap;

  ${to('md')} {
    width: 100%;
    white-space: unset;
  }
`;
