import styled, { from, to } from 'theme';

export const ProcessIconArrow = styled.div`
  background-image: url(${props => props.theme.variables.icons.svg.chevronDown});
  background-size: cover;
`;

export const ProcessesWrapper = styled.div`
  display: flex;
  flex-flow: column;

  ${from('md')} {
    flex-flow: row;
    justify-content: space-around;
    padding-bottom: 1rem;
  }
`;

export const ProcessItem = styled.div`
  display: flex;
  flex-flow: column;

  &:last-child {
    .process__arrow {
      display: none;
    }
  }

  ${to('md')} {
    margin-top: 3rem;
  }
`;

export const ProcessIcon = styled.img`
  margin: 0 auto 1rem;
  width: 7.125rem;

  ${from('md')} {
    margin: 0 auto 3rem;
  }
`;

export const ProcessTitle = styled.span`
  font-weight: ${props => props.theme.variables.fonts.weight.medium};
  font-size: ${props => props.theme.variables.fonts.size.bigger1};
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  text-align: center;
  position: relative;

  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: stretch;

  .process__arrow {
    display: none;

    ${from('md')} {
      display: flex;
      margin: 0;
      position: absolute;
      transform: rotate(-90deg);
      right: -2rem;
      top: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  ${to('md')} {
    margin-top: 2rem;
  }

  ${from('md')} {
    height: 2.5rem;
    max-width: 6rem;
    font-size: ${props => props.theme.variables.fonts.size.smaller0};
  }

  ${from('lg')} {
    width: 10rem;
    max-width: 10rem;
    font-size: ${props => props.theme.variables.fonts.size.bigger1};
  }
`;
