import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';
import { submitForm } from 'store/api/portal/submitForm/submitForm';
import { CreateSupportIssueResponse } from 'store/api/portal/support/support.types';
import { createSupportIssue } from 'store/api/portal/support/supportActions';

import { ContactPage } from './ContactPage';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onCreateSupportIssue: submitForm<CreateSupportIssueResponse>(dispatch, createSupportIssue),
});

export const ContactPageContainer = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(ContactPage);
