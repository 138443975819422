import React from 'react';

import {
  AlertBoxContainer,
  AlertBoxContent,
  AlertBoxFailIcon,
  AlertBoxSuccessIcon,
  AlertBoxTitle,
} from './AlertBox.styles';
import { AlertBoxProps, AlertBoxTypes } from './AlertBox.types';

export const AlertBox = ({ title, children, type }: AlertBoxProps) => {
  return (
    <AlertBoxContainer className={`alert-box alert-box--${type === AlertBox.types.SUCCESS ? 'success' : 'fail'}`}>
      {type === AlertBox.types.SUCCESS && <AlertBoxSuccessIcon className="alert-box__icon alert-box__icon--success" />}
      {type === AlertBox.types.FAIL && <AlertBoxFailIcon className="alert-box__icon alert-box__icon--fail" />}
      <AlertBoxTitle className="alert-box__title">{title}</AlertBoxTitle>
      <AlertBoxContent className="alert-box__content">{children}</AlertBoxContent>
    </AlertBoxContainer>
  );
};

AlertBox.types = AlertBoxTypes;
