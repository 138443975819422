import React from 'react';
import { Link as InternalLink } from 'react-router-dom';

import { LinkElement } from './Link.styles';
import { LinkProps } from './Link.types';

export const Link = ({ children, className, external, label, onClick, url, target, style }: LinkProps) => (
  <LinkElement
    className={className ? className : ''}
    href={url}
    to={external ? undefined : url}
    as={external ? undefined : InternalLink}
    onClick={onClick}
    target={target}
    style={style}
  >
    {children || label}
  </LinkElement>
);
