import styled, { from } from 'theme';

export const FooterBlockWrapper = styled.section`
  font-family: ${props => props.theme.variables.typography.secondaryFont};
  padding: 2.25rem;
  border: ${props => props.theme.variables.borders.width.bigger} solid
    ${props => props.theme.variables.customColors.boxBorder};
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  ${from('md')} {
    flex-flow: row;
    padding: 3.25rem;
  }
`;

export const FooterBlockContent = styled.div`
  margin-bottom: 1.25rem;
  display: block;
  width: 100%;

  .footer-block__link {
    color: white;
    margin: 2rem 0 0 0;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    border-radius: 2.5rem;
    display: inline-flex;

    ${from('md')} {
      display: flex;
      max-width: 14rem;
    }
  }

  ${from('md')} {
    margin-bottom: 0;
  }
`;

export const FooterBlockImage = styled.div`
  display: block;
  align-self: stretch;
  background-repeat: no-repeat;
  margin: 0 -2.25rem -2.25rem;
  background-size: cover;
  background-position: top left;
  min-height: 20rem;

  ${from('md')} {
    flex: 0 0 40%;
    margin: 0 0 0 2.5rem;
    background-size: 100% auto;
    background-position: top center;
    min-height: 10rem;
  }

  ${from('lg')} {
    flex: 0 0 60%;
  }
`;

export const FooterBlockTitle = styled.h3`
  font-weight: ${props => props.theme.variables.fonts.weight.regular};
  font-size: 2.125rem;
  margin-bottom: 1rem;
  line-height: 1.3;
`;

export const FooterBlockDescription = styled.span`
  font-size: ${props => props.theme.variables.fonts.size.bigger1};
  font-weight: ${props => props.theme.variables.fonts.weight.extraLight};
  line-height: 1.4;
`;
