import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dispatch } from 'redux-api-middleware';
import { BottomNavigationResponse } from 'store/api/cms/bottomNavigation/bottomNavigation.types';
import { fetchBottomNavigation } from 'store/api/cms/bottomNavigation/bottomNavigationActions';

import { Footer } from './Footer';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchBottomNavigation: () => dispatch<Promise<BottomNavigationResponse>>(fetchBottomNavigation()),
});

export const FooterContainer = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(Footer);
