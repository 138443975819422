import { FieldState } from 'final-form';

type validatorType = (value: any, allValues?: object, meta?: FieldState | null) => any | undefined;

export const combineValidators = (...validators: validatorType[]) => (
  value: any,
  allValues?: object,
  meta?: FieldState | null,
) => {
  return validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined);
};
