import React from 'react';
import { Link } from 'shared';

import { LinksWrapper, NewsBlockWrapper, NewsDescription, NewsImage, NewsTitle } from './NewsBlock.styles';
import { NewsBlockProps } from './NewsBlock.types';

export const NewsBlock = ({ title, description, image, links }: NewsBlockProps) => (
  <NewsBlockWrapper className="news__item">
    <NewsImage style={{ backgroundImage: `url(${image})` }} className="news__image" />
    <NewsTitle className="news__title">{title}</NewsTitle>
    <NewsDescription className="news__description">{description}</NewsDescription>
    <LinksWrapper className="news_links">
      {links.map((link, index) => (
        <Link {...link} className="news__link" key={index} />
      ))}
    </LinksWrapper>
  </NewsBlockWrapper>
);
