import React from 'react';
import { Column } from 'shared';

import { NewsWrapper } from './News.styles';
import { NewsProps } from './News.types';
import { NewsBlock } from './newsBlock/NewsBlock';

export const News = ({ news }: NewsProps) => (
  <NewsWrapper className="news">
    {news.map((newsItem, index) => (
      <Column key={index} size={[12, 6, 4]} className="news__column">
        <NewsBlock {...newsItem} />
      </Column>
    ))}
  </NewsWrapper>
);
