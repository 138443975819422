import ReactDOM from 'react-dom';

import { PortalProps } from './Portal.types';

export const Portal = ({ children, portalRoot: portalRootId }: PortalProps) => {
  let portalRoot = document.getElementById(portalRootId);

  if (!portalRoot) {
    portalRoot = document.createElement('div');
    portalRoot.setAttribute('id', portalRootId);
    portalRoot.setAttribute('data-testid', portalRootId);
    document.body.appendChild(portalRoot);
  }

  return ReactDOM.createPortal(children, portalRoot!);
};
