import React, { ChangeEvent, useContext, useState } from 'react';
import { LocaleContext } from 'shared';

import {
  SearchInput,
  SearchInputWrapper,
  SidebarItem,
  SidebarItems,
  SidebarMobileSelect,
  SidebarWrapper,
} from './Sidebar.styles';
import { SidebarProps } from './Sidebar.types';

export const Sidebar = ({ active, onClick, groupTitles, search: isSearchEnabled, onSearch }: SidebarProps) => {
  const context = useContext(LocaleContext);
  const [search, setSearch] = useState('');
  const handleOnClick = (index: number) => () => onClick(index);
  const handleOnSelect = (event: ChangeEvent<HTMLSelectElement>) => onClick(parseInt(event.target.value, 10));

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (onSearch) {
      onSearch(event);
    }
  };

  return (
    <SidebarWrapper className="sidebar">
      {isSearchEnabled && (
        <SearchInputWrapper>
          <SearchInput
            placeholder={context.formatMessage({ id: 'GENERAL.SEARCH' })}
            value={search}
            onChange={handleSearch}
            className="sidebar__search"
          />
        </SearchInputWrapper>
      )}
      <SidebarItems className={`sidebar__items`}>
        <SidebarMobileSelect value={active} onChange={handleOnSelect} className="sidebar__select">
          {groupTitles.map((groupTitle, index) => (
            <option key={index} value={index}>
              {groupTitle}
            </option>
          ))}
        </SidebarMobileSelect>
        {groupTitles.map((groupTitle, index) => (
          <SidebarItem
            key={index}
            active={active === index}
            onClick={handleOnClick(index)}
            className={`sidebar__item ${active === index ? 'sidebar__item--active' : ''}`}
          >
            {groupTitle}
          </SidebarItem>
        ))}
      </SidebarItems>
    </SidebarWrapper>
  );
};
