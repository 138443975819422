import styled from 'theme';

export const CollapseWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const CollapseTitle = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const CollapseContent = styled.div<{ height: number | null }>`
  display: flex;
  height: ${props => (props.height ? `100%` : '0px')};
  flex-flow: column;
  transition: height 0.2s;
  overflow: hidden;
`;
