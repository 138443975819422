import React from 'react';
import {
  Column,
  FooterBlock,
  HeaderBlock,
  HowToGroupedContent,
  Loader,
  LocaleContext,
  Message,
  Query,
  Row,
  Section,
} from 'shared';
import { adaptApiLink, getApiResourceUrl } from 'utils';

import { HowToProps } from './HowTo.types';

export const HowTo = ({ onFetchHowTo }: HowToProps) => (
  <LocaleContext.Consumer>
    {({ formatMessage }) => (
      <Query onFetch={onFetchHowTo}>
        {({ error, loading, response }) => (
          <Row height="100%" style={{ flexFlow: 'column' }}>
            {response &&
              response.payload.banners &&
              response.payload.banners.map(
                banner =>
                  banner.type === 'HERO' && (
                    <Column mx={[1, 2]} mt={[1, 1, 0]} mb={[1, 0]} key={banner.id} flexBasis="auto">
                      <HeaderBlock
                        description={banner.content}
                        image={banner.image ? getApiResourceUrl(banner.image) : ''}
                        link={banner.link ? adaptApiLink(banner.link) : undefined}
                        title={banner.title}
                        overlayColor={banner.overlayColor}
                      />
                    </Column>
                  ),
              )}

            <Column flexBasis="auto">
              {loading && <Loader />}

              {error && (
                <Message title={formatMessage({ id: 'LIST.ERROR.TITLE' })}>
                  {formatMessage({ id: 'LIST.ERROR.CONTENT' })}
                </Message>
              )}

              {!loading &&
                response &&
                (!response.payload.howtosections ||
                  (response.payload.howtosections && response.payload.howtosections.length === 0)) && (
                  <Message title={formatMessage({ id: 'LIST.EMPTY.TITLE' })}>
                    {formatMessage({ id: 'LIST.EMPTY.CONTENT' })}
                  </Message>
                )}

              {!loading && response && response.payload.howtosections && response.payload.howtosections.length > 0 && (
                <HowToGroupedContent groups={response.payload.howtosections} />
              )}
            </Column>
            {response &&
              response.payload.banners &&
              response.payload.banners.map(
                banner =>
                  banner.type === 'FOOTER' && (
                    <Column mx={[1, 2, 2, 6]} mt={[1, '2rem']} mb={[0, '2rem']} key={banner.id}>
                      <Section>
                        <FooterBlock
                          description={banner.content}
                          image={banner.image ? getApiResourceUrl(banner.image) : ''}
                          link={banner.link ? adaptApiLink(banner.link) : undefined}
                          title={banner.title}
                        />
                      </Section>
                    </Column>
                  ),
              )}
          </Row>
        )}
      </Query>
    )}
  </LocaleContext.Consumer>
);
