import styled, { from } from 'theme';

import { Form } from '../../../shared';

export const ReCaptchaPolicy = styled.p`
  font-size: 0.7rem;
  margin-top: 0.5rem;

  a {
    color: ${props => props.theme.variables.colors.red};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContactFormWrapper = styled(Form)`
  .contact-form__name {
    input {
      @-moz-document url-prefix() {
        ${from('lg')} {
          width: 75%;
        }
      }
    }
  }
  .contact-form__email {
    input {
      @-moz-document url-prefix() {
        ${from('lg')} {
          width: 75%;
        }
      }
    }
  }
`;
