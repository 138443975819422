import { RSAA, RSAAction } from 'redux-api-middleware';
import { CMS_API } from 'store/api';

import * as types from './staticPageActionTypes';

export const fetchStaticPage = (type: string): RSAAction<string, string, string> => ({
  [RSAA]: {
    api: CMS_API,
    endpoint: `staticpage/${type}`,
    method: 'GET',
    types: [types.FETCH_STATIC_PAGE_REQUEST, types.FETCH_STATIC_PAGE_SUCCESS, types.FETCH_STATIC_PAGE_FAILURE],
  },
});

export const fetchStaticPageBySlug = (slug: string): RSAAction<string, string, string> => ({
  [RSAA]: {
    api: CMS_API,
    endpoint: `staticpage/slug/${slug}`,
    method: 'GET',
    types: [types.FETCH_STATIC_PAGE_REQUEST, types.FETCH_STATIC_PAGE_SUCCESS, types.FETCH_STATIC_PAGE_FAILURE],
  },
});
