import * as Sentry from '@sentry/browser';
import { Component, ErrorInfo } from 'react';
import { getEnvironmentVar } from 'utils';

export class SentryBoundary extends Component {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (getEnvironmentVar('REACT_APP_SENTRY_KEY') && getEnvironmentVar('REACT_APP_SENTRY_PROJECT')) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    return this.props.children;
  }
}
